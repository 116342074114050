import { forwardRef, Ref, useMemo } from 'react'
import {
  NumberInput as MantineNumberInput,
  NumberInputProps as MantineNumberInputProps,
} from '@mantine/core'
import clsx from 'clsx'

import classes from './number-input.module.css'

type TMantineNumberInputProps = Omit<MantineNumberInputProps, 'size' | 'classNames'>

type TSizeTextInputVariants = 'small' | 'medium' | 'large'

type TClassNamesTextInput = {
  root?: string
  label?: string
  required?: string
  description?: string
  wrapper?: string
  input?: string
  section?: string
  controls?: string
  control?: string
  error?: string
}

export interface INumberInputProps extends TMantineNumberInputProps {
  classNames?: TClassNamesTextInput
  size?: TSizeTextInputVariants
  hasFilledIcon?: boolean
}

function NumberInput(
  { classNames, size = 'medium', hasFilledIcon, ...rest }: INumberInputProps,
  ref: Ref<HTMLInputElement>,
) {
  const numberInputClassNames = useMemo(() => {
    const { label, description, input, section, error } = classNames || {}

    if (size === 'small') {
      return {
        label: clsx(
          'mb-1 ml-4 font-semibold md:font-normal',
          classes.numberInputLabel,
          label ? label : '',
        ),
        description: clsx(
          'ml-4 mr-4',
          classes.numberInputDescription,
          description ? description : '',
        ),
        error: clsx('mt-2 ml-4 mr-4', classes.numberInputError, error ? error : ''),
        input: clsx(
          'h-[32px] rounded-[18px]',
          rest.leftSection ? 'pl-[32px]' : 'pl-[16px]',
          rest.rightSection ? 'pr-[32px]' : 'pr-[32px]',
          classes.numberInputCore,
          input ? input : '',
        ),
        section: clsx(
          'h-[32px] w-[32px] flex items-center justify-center',
          hasFilledIcon ? classes.numberInputSectionFilledIcon : '',
          classes.numberInputSection,
          section ? section : '',
        ),
      }
    }

    if (size === 'large') {
      return {
        label: clsx(
          'mb-1 ml-6 font-semibold md:font-normal',
          classes.numberInputLabel,
          label ? label : '',
        ),
        description: clsx(
          'ml-6 mr-6',
          classes.numberInputDescription,
          description ? description : '',
        ),
        error: clsx('mt-2 ml-6 mr-6', classes.numberInputError, error ? error : ''),
        input: clsx(
          'h-[56px] rounded-[25px]',
          rest.leftSection ? 'pl-[56px]' : 'pl-[25px]',
          rest.rightSection ? 'pr-[56px]' : 'pr-[50px]',
          classes.numberInputCore,
          input ? input : '',
        ),
        section: clsx(
          'h-[56px] w-[56px] flex items-center justify-center',
          hasFilledIcon ? classes.numberInputSectionFilledIcon : '',
          classes.numberInputSection,
          section ? section : '',
        ),
      }
    }

    return {
      label: clsx(
        'mb-1 ml-5 font-semibold md:font-normal',
        classes.numberInputLabel,
        label ? label : '',
      ),
      description: clsx(
        'ml-5 mr-5',
        classes.numberInputDescription,
        description ? description : '',
      ),
      error: clsx('mt-2 ml-5 mr-6', classes.numberInputError, error ? error : ''),
      input: clsx(
        'h-[48px] rounded-[30px]',
        rest.leftSection ? 'pl-[48px]' : 'pl-[18px]',
        rest.rightSection ? 'pr-[48px]' : 'pr-[36px]',
        classes.numberInputCore,
        input ? input : '',
      ),
      section: clsx(
        'h-[48px] w-[48px] flex items-center justify-center',
        hasFilledIcon ? classes.numberInputSectionFilledIcon : '',
        classes.numberInputSection,
        section ? section : '',
      ),
    }
  }, [classNames, size, rest.leftSection, rest.rightSection, hasFilledIcon])

  return (
    <MantineNumberInput
      classNames={{
        root: clsx(
          'w-full',
          classes.numberInputRoot,
          classNames?.root ? classNames.root : '',
        ),
        required: clsx(
          '',
          classes.numberInputRequired,
          classNames?.required ? classNames.required : '',
        ),
        wrapper: clsx(
          '',
          classes.numberInputWrapper,
          classNames?.wrapper ? classNames.wrapper : '',
        ),
        controls: clsx(
          'flex flex-col items-center w-[25px]',
          classes.numberInputControls,
          classNames?.controls ? classNames.controls : '',
        ),
        control: clsx(
          'w-[14px] h-[14px] m-0 p-0',
          classes.numberInputControl,
          classNames?.control ? classNames.control : '',
        ),
        ...numberInputClassNames,
      }}
      ref={ref}
      {...rest}
    />
  )
}

export default forwardRef(NumberInput)
