export enum RoutesEnum {
  LOGIN = '/login',
  RESET_PASSWORD = '/resetPassword',
  RESET_PASSWORD_WITH_TOKEN = '/resetPassword/:resetPasswordToken',
  HOME = '/',
  SETTINGS = '/settings',
  ACTIVITIES = '/activities',
}

export const ROUTES = {
  home: () => '/',
  login: () => '/login',
  resetPassword: (resetPasswordToken?: string) => `/resetPassword/${resetPasswordToken}`,
  settings: () => '/settings',
  activities: () => '/activities',
}
