import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Anchor, Text, Title } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import { useGetUserSitesQuery } from 'auth/api'
import {
  AuthLoginStep,
  IResponseLoginApplication,
  IResponseLoginClient,
} from 'auth/models'
import {
  clearUser,
  clearUserApplicationid,
  clearUserClientId,
  clearUserSiteId,
  setLoginStep,
} from 'auth/store'
import { BASIC_GUID } from 'auth/utils'
import clsx from 'clsx'
import { Button, ErrorAlert, MoonLoader } from 'common/components'
import { useDispatch, useSelector } from 'common/hooks'
import { isEmpty, isNil } from 'lodash'
import { auth, ROUTES, RoutesEnum } from 'services'

import classes from './../components.module.css'

export default function LoginSelectSiteForm() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const matches = useMediaQuery('(min-width: 768px)')
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const applicationId = localStorage.getItem('application_id') || ''
  const clientId = localStorage.getItem('client_id') || ''
  const hasClients = useMemo(() => {
    const clients = localStorage.getItem('clients')
    const parsedClients: IResponseLoginClient[] = JSON.parse(clients || '')

    return clients && parsedClients.length > 1
  }, [])
  const hasApplications = useMemo(() => {
    const applications = localStorage.getItem('applications')
    const parsedApplications: IResponseLoginApplication[] = JSON.parse(applications || '')

    return applications && parsedApplications.length > 1
  }, [])

  const { data: response, isFetching } = useGetUserSitesQuery(
    user?.userId || '',
    clientId,
    applicationId,
    {
      enabled: Boolean(applicationId) && Boolean(clientId),
    },
  )
  const isLoading = useMemo(() => isNil(response) && isFetching, [isFetching, response])

  const handleSelectSiteClick = useCallback(
    (siteId: string) => {
      localStorage.setItem('site_id', siteId)
      navigate(RoutesEnum.HOME)
    },
    [navigate],
  )

  const handleCancelLoginClick = () => {
    dispatch(clearUser())
    auth.handleCancelSiteLoginClick()
    dispatch(setLoginStep({ step: AuthLoginStep.SIGN_IN }))
  }

  const handlePrevStepClick = useCallback(() => {
    if (hasApplications) {
      auth.handlePrevStepSiteLoginToApplicationsClick()
      dispatch(clearUserApplicationid())
      dispatch(clearUserSiteId())
      dispatch(setLoginStep({ step: AuthLoginStep.SELECT_APPLICATION }))
    } else if (hasClients) {
      auth.handlePrevStepSiteLoginToClientsClick()
      dispatch(clearUserClientId())
      dispatch(clearUserApplicationid())
      dispatch(clearUserSiteId())
      dispatch(setLoginStep({ step: AuthLoginStep.SELECT_CLIENT }))
    }
  }, [hasApplications, hasClients, dispatch])

  const renderContent = useCallback(() => {
    const siteId = user?.siteId

    if (isNil(response) && isEmpty(response) && siteId === BASIC_GUID) {
      return (
        <ErrorAlert
          className={'flex justify-center'}
          content={t('auth.noSitesDescription')}
          icon={false}
          title={t('auth.noSitesTitle')}
        />
      )
    }

    return (
      <>
        <div className={clsx('flex flex-col', classes.loginFormSelectWrapperHeader)}>
          <Anchor
            className={clsx(
              'flex items-center gap-1 mb-[15px] text-[18px] leading-[24px] font-medium no-underline duration-100 md:text-[22px]',
              classes.loginFormSelectBackLink,
            )}
            component={Link}
            onClick={handlePrevStepClick}
            to={ROUTES.login()}
          >
            <IconChevronLeft size={24} />
            {t('auth.control.goBack')}
          </Anchor>
          <Title
            className={'my-0 text-[24px] leading-[33px] font-semibold md:text-[32px]'}
            order={2}
          >
            {t('auth.selectSite')}
          </Title>
          <Text className={'mb-4 mt-[10px] text-[16px] leading-[24px] md:text-[20px]'}>
            {t('auth.selectSiteSub')}
          </Text>
        </div>
        <div
          className={
            'w-full flex flex-col items-center gap-4 md:gap-6 overflow-x-hidden pt-6 md:w-auto md:items-start md:pt-0 md:pr-4 md:mr-[70px] md:mt-[45px] md:overflow-y-auto'
          }
        >
          {response?.map((item) => (
            <Button
              classNames={{
                root: clsx(
                  'w-full max-w-[340px] h-[50px] min-h-[50px] overflow-visible rounded-md bg-white text-black md:max-w-[600px] md:mr-[20px]',
                  classes.loginFormSelectButton,
                ),
                inner: 'flex justify-between',
                label: 'text-[18px] leading-[33px] font-normal',
              }}
              key={item.id}
              onClick={() => handleSelectSiteClick(item.id)}
              rightSection={<IconChevronRight stroke={1.5} width={30} />}
            >
              {item.name}
            </Button>
          ))}
        </div>
      </>
    )
  }, [handlePrevStepClick, handleSelectSiteClick, response, t, user?.siteId])

  useEffect(() => {
    if (response) {
      localStorage.setItem('sites', JSON.stringify(response))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  useEffect(() => {
    if (user && response) {
      const siteId = user.siteId

      if (siteId !== BASIC_GUID || response.length === 1) {
        localStorage.setItem('site_id', isEmpty(response) ? siteId : response[0].id)
        localStorage.setItem('sites', JSON.stringify(response))
        dispatch(setLoginStep({ step: AuthLoginStep.INDEX }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  return (
    <div
      className={'w-full h-full flex flex-col items-stretch my-auto md:h-auto md:block'}
    >
      <div
        className={clsx(
          'h-full w-full flex flex-col py-[58px] my-auto md:max-h-full md:max-w-[800px] md:px-[40px] md:py-[60px] md:rounded-lg md:bg-white xl:max-h-[80vh]',
          matches ? classes.loginFormSelectContent : '',
        )}
      >
        {isLoading ? <MoonLoader text={t('auth.loadingSites')} /> : renderContent()}
      </div>
      <div className={'pb-[30px] md:pb-0 md:mt-[30px]'}>
        <Anchor
          className={clsx(
            'h-[48px] w-full flex items-center justify-center text-center no-underline rounded-[30px] text-[20px] leading-[28px] font-semibold duration-100 md:h-[60px] md:w-[400px] md:text-[24px]',
            classes.loginFormSelectCancelLogin,
          )}
          component={Link}
          onClick={handleCancelLoginClick}
          to={RoutesEnum.LOGIN}
        >
          {t('auth.control.cancelLogin')}
        </Anchor>
      </div>
    </div>
  )
}
