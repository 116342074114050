import { Button as MantineButton, ButtonProps } from '@mantine/core'

type TMantineButtonProps = Omit<ButtonProps, 'disabled' | 'loading' | 'type'>

export interface IButtonProps
  extends TMantineButtonProps,
    Omit<React.ComponentPropsWithoutRef<'button'>, keyof ButtonProps> {
  isDisabled?: boolean
  isLoading?: boolean
}

export default function Button({
  children,
  isDisabled,
  isLoading,
  ...rest
}: IButtonProps) {
  return (
    <MantineButton disabled={isDisabled} loading={isLoading} {...rest}>
      {children}
    </MantineButton>
  )
}
