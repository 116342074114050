import { useEffect } from 'react'
import { i18n } from 'services'
import * as yup from 'yup'

export default function useYupLocale() {
  useEffect(() => {
    yup.setLocale({
      mixed: {
        required: () => i18n.t('validation.required'),
      },
      string: {
        email: () => i18n.t('validation.incorrectEmail'),
        max: ({ max }) => i18n.t('validation.max', { characters: max }),
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])
}
