import { IClientAssets } from 'common/models'

import { mannHummelTheme, walsteadTheme, xeltoTheme } from '../themes'

import mannHummelLogo from './../../assets/mann-hummel-logo.png'
import walsteadLogo from './../../assets/walstead-logo.png'
import xeltoLogoColor from './../../assets/xelto-logo.png'
import xeltoLogo from './../../assets/xeltoLogo.svg'

enum DomainNameEnum {
  MANN_HUMMEL = 'mannHummel',
  WALSTEAD = 'walstead',
  XELTO = 'xelto',
}

export default function useThemeConfig(domain?: string): IClientAssets {
  if (domain?.includes(DomainNameEnum.MANN_HUMMEL)) {
    return {
      theme: mannHummelTheme,
      logo: mannHummelLogo,
      logoColor: mannHummelLogo,
    }
  }

  if (domain?.includes(DomainNameEnum.WALSTEAD)) {
    return {
      theme: walsteadTheme,
      logo: walsteadLogo,
      logoColor: walsteadLogo,
    }
  }

  return {
    theme: xeltoTheme,
    logo: xeltoLogo,
    logoColor: xeltoLogoColor,
  }
}
