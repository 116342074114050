import { forwardRef, Ref, useMemo } from 'react'
import {
  PasswordInput as MantinePasswordInput,
  PasswordInputProps as MantinePasswordInputProps,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconEye, IconEyeOff } from '@tabler/icons-react'
import clsx from 'clsx'

import classes from './password-input.module.css'

type TMantinePasswordInputProps = Omit<
  MantinePasswordInputProps,
  'defaultVisible' | 'disabled' | 'required' | 'pointer' | 'size' | 'classNames'
>

type TSizePasswordInputVariants = 'small' | 'medium' | 'large'

type TClassNamesPasswordInput = {
  root?: string
  label?: string
  required?: string
  description?: string
  wrapper?: string
  input?: string
  innerInput?: string
  section?: string
  error?: string
  visibilityToggle?: string
}

export interface IPasswordInputProps extends TMantinePasswordInputProps {
  classNames?: TClassNamesPasswordInput
  size?: TSizePasswordInputVariants
  isDefaultVisible?: boolean
  isRequired?: boolean
  isDisabled?: boolean
  hasPointerCursor?: boolean
  hasFilledIcon?: boolean
}

function PasswordInput(
  {
    size = 'medium',
    isRequired,
    isDisabled,
    hasPointerCursor,
    hasFilledIcon,
    classNames,
    ...rest
  }: IPasswordInputProps,
  ref: Ref<HTMLInputElement>,
) {
  const [visible, { toggle }] = useDisclosure(false)
  const passwordInputClassNames = useMemo(() => {
    const { label, description, input, innerInput, section, error } = classNames || {}

    if (size === 'small') {
      return {
        label: clsx(
          'mb-1 ml-4 font-semibold md:font-normal',
          classes.passwordInputLabel,
          label ? label : '',
        ),
        description: clsx(
          'ml-4 mr-4',
          classes.passwordInputDescription,
          description ? description : '',
        ),
        error: clsx('mt-2 ml-4 mr-4', classes.passwordInputError, error ? error : ''),
        input: clsx(
          'h-[32px] rounded-[18px]',
          rest.leftSection ? 'pl-[32px]' : 'pl-[16px]',
          rest.rightSection ? 'pr-[32px]' : 'pr-[16px]',
          classes.passwordInputCore,
          input ? input : '',
        ),
        innerInput: clsx(
          '',
          classes.passwordInputInnerInput,
          innerInput ? innerInput : '',
        ),
        section: clsx(
          'h-[32px] w-[32px] flex items-center justify-center',
          hasFilledIcon ? classes.passwordInputSectionFilledIcon : '',
          classes.passwordInputSection,
          section ? section : '',
        ),
      }
    }

    if (size === 'large') {
      return {
        label: clsx(
          'mb-1 ml-6 font-semibold md:font-normal',
          classes.passwordInputLabel,
          label ? label : '',
        ),
        description: clsx(
          'ml-6 mr-6',
          classes.passwordInputDescription,
          description ? description : '',
        ),
        error: clsx('mt-2 ml-6 mr-6', classes.passwordInputError, error ? error : ''),
        input: clsx(
          'h-[56px] rounded-[25px]',
          classes.passwordInputCore,
          input ? input : '',
        ),
        innerInput: clsx(
          rest.leftSection ? 'pl-[56px]' : 'pl-[25px]',
          rest.rightSection ? 'pr-[56px]' : 'pr-[25px]',
          classes.passwordInputInnerInput,
          innerInput ? innerInput : '',
        ),
        section: clsx(
          'h-[56px] w-[56px] flex items-center justify-center',
          hasFilledIcon ? classes.passwordInputSectionFilledIcon : '',
          classes.passwordInputSection,
          section ? section : '',
        ),
      }
    }

    return {
      label: clsx(
        'mb-1 ml-5 font-semibold md:font-normal',
        classes.passwordInputLabel,
        label ? label : '',
      ),
      description: clsx(
        'ml-5 mr-5',
        classes.passwordInputDescription,
        description ? description : '',
      ),
      error: clsx('mt-2 ml-5 mr-6', classes.passwordInputError, error ? error : ''),
      input: clsx(
        'h-[48px] rounded-[30px]',
        rest.leftSection ? 'pl-[48px]' : 'pl-[18px]',
        rest.rightSection ? 'pr-[48px]' : 'pr-[18px]',
        classes.passwordInputCore,
        input ? input : '',
      ),
      innerInput: clsx('', classes.passwordInputInnerInput, innerInput ? innerInput : ''),
      section: clsx(
        'h-[48px] w-[48px] flex items-center justify-center',
        hasFilledIcon ? classes.passwordInputSectionFilledIcon : '',
        classes.passwordInputSection,
        section ? section : '',
      ),
    }
  }, [classNames, size, rest.leftSection, rest.rightSection, hasFilledIcon])

  return (
    <MantinePasswordInput
      classNames={{
        root: clsx(
          'w-full',
          classes.passwordInputRoot,
          classNames?.root ? classNames.root : '',
        ),
        required: clsx(
          '',
          classes.passwordInputRequired,
          classNames?.required ? classNames.required : '',
        ),
        wrapper: clsx(
          '',
          classes.passwordInputWrapper,
          classNames?.wrapper ? classNames.wrapper : '',
        ),
        visibilityToggle: clsx(
          '',
          classes.passwordInputVisibilityToggle,
          classNames?.visibilityToggle ? classNames.visibilityToggle : '',
        ),
        ...passwordInputClassNames,
      }}
      disabled={isDisabled}
      onVisibilityChange={toggle}
      pointer={hasPointerCursor}
      ref={ref}
      required={isRequired}
      // eslint-disable-next-line react/no-unstable-nested-components
      visibilityToggleIcon={({ reveal }) => (reveal ? <IconEyeOff /> : <IconEye />)}
      visible={visible}
      {...rest}
    />
  )
}

export default forwardRef(PasswordInput)
