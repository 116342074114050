import { useMutation } from '@tanstack/react-query'
import { IRequestLogin, IResponseLogin } from 'auth/models'
import { TMutationOptions } from 'common/models'
import { api } from 'services'

import { ApiKeysEnum } from './apiKeys'

const login = async (body: IRequestLogin) => {
  return await api.post<IResponseLogin>('client/login', body)
}

export default function useLoginMutation(options: TMutationOptions<IRequestLogin> = {}) {
  return useMutation({ mutationKey: [ApiKeysEnum.LOGIN], mutationFn: login, ...options })
}
