import { createTheme, MantineThemeOverride } from '@mantine/core'

const walsteadTheme: MantineThemeOverride = createTheme({
  fontFamily: 'Barlow, sans-serif',
  fontSizes: {
    xs: '16px',
  },
  colors: {
    mainColor: [
      '#FC1895', // LIGHT COLOR 0
      '#FC1895', // LIGHT COLOR 1
      '#FC1895', // LIGHT COLOR 2
      '#E5007D', // NORMAL COLOR 3
      '#E5007D', // NORMAL COLOR 4
      '#E5007D', // NORMAL COLOR 5
      '#E5007D', // NORMAL COLOR 6
      '#D5137D', // DARK COLOR 7
      '#D5137D', // DARK COLOR 8
      '#D5137D', // DARK COLOR 9
    ],
  },
  primaryColor: 'mainColor',
})

export default walsteadTheme
