import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyBqp9hPuuharkzV0itsmLplbZMX4Uc38-Y',
  authDomain: 'xelto-apps.firebaseapp.com',
  projectId: 'xelto-apps',
  storageBucket: 'xelto-apps.appspot.com',
  messagingSenderId: '999891310160',
  appId: '1:999891310160:web:9b68cb70467ad7c6ef6355',
  measurementId: 'G-M3780WYZPT',
}

// Firebase initialization
const app = initializeApp(firebaseConfig)
export const messaging = getMessaging(app)
