export class Auth {
  constructor() {}

  // START: LoginSelectClientForm
  handleCancelClientLoginClick() {
    localStorage.removeItem('access_token')
    localStorage.removeItem('clients')
    localStorage.removeItem('user_id')
  }
  // END: LoginSelectClientForm

  // START: LoginSelectApplicationForm
  handleCancelApplicationLoginClick() {
    localStorage.removeItem('client_id')
    localStorage.removeItem('clients')
    localStorage.removeItem('applications')
    localStorage.removeItem('access_token')
    localStorage.removeItem('user_id')
  }

  handlePrevStepApplicationLoginClick() {
    localStorage.removeItem('client_id')
    localStorage.removeItem('clients')
    localStorage.removeItem('applications')
  }
  // END: LoginSelectApplicationForm

  // START: LoginSelectSiteForm
  handleCancelSiteLoginClick() {
    localStorage.removeItem('sites')
    localStorage.removeItem('application_id')
    localStorage.removeItem('applications')
    localStorage.removeItem('application_url')
    localStorage.removeItem('client_id')
    localStorage.removeItem('clients')
    localStorage.removeItem('access_token')
    localStorage.removeItem('user_id')
  }

  handlePrevStepSiteLoginToClientsClick() {
    localStorage.removeItem('sites')
    localStorage.removeItem('applications')
    localStorage.removeItem('application_id')
    localStorage.removeItem('application_url')
    localStorage.removeItem('clients')
    localStorage.removeItem('client_id')
  }

  handlePrevStepSiteLoginToApplicationsClick() {
    localStorage.removeItem('sites')
    localStorage.removeItem('applications')
    localStorage.removeItem('application_id')
    localStorage.removeItem('application_url')
  }
  // END: LoginSelectSiteForm

  // START: UserMenu
  handleChangeClientClick() {
    localStorage.removeItem('application_id')
    localStorage.removeItem('applications')
    localStorage.removeItem('site_id')
    localStorage.removeItem('sites')
    localStorage.removeItem('application_navigation')
    localStorage.removeItem('application_routing')
  }

  handleChangeApplicationClick() {
    localStorage.removeItem('site_id')
    localStorage.removeItem('sites')
    localStorage.removeItem('application_navigation')
    localStorage.removeItem('application_routing')
  }

  handleLogoutClick() {
    localStorage.removeItem('application_url')
    localStorage.removeItem('client_id')
    localStorage.removeItem('application_id')
    localStorage.removeItem('site_id')
    localStorage.removeItem('clients')
    localStorage.removeItem('applications')
    localStorage.removeItem('sites')
    localStorage.removeItem('access_token')
    localStorage.removeItem('user_id')
    localStorage.removeItem('i18nextLng')
    localStorage.removeItem('application_navigation')
    localStorage.removeItem('application_routing')
    localStorage.removeItem('application_name')
  }
  // END: UserMenu
}

const auth = new Auth()

export default auth
