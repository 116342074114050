import { useMutation } from '@tanstack/react-query'
import { IRequestSetUser, IResponseLogin } from 'auth/models'
import { TMutationOptions } from 'common/models'
import { api } from 'services'

import { ApiKeysEnum } from './apiKeys'

const setUser = async ({ clientId, applicationId, siteId }: IRequestSetUser) => {
  return await api.post<IResponseLogin>(
    `client/user/client/${clientId}/application/${applicationId}/site/${siteId}/set`,
  )
}

export default function useSetUserMutation(
  userId: string,
  options: TMutationOptions<IRequestSetUser> = {},
) {
  return useMutation({
    mutationKey: [ApiKeysEnum.SET_USER, userId],
    mutationFn: setUser,
    ...options,
  })
}
