import { forwardRef, Ref, useMemo } from 'react'
import {
  TextInput as MantineTextInput,
  TextInputProps as MantineTextInputProps,
} from '@mantine/core'
import clsx from 'clsx'

import classes from './text-input.module.css'

type TMantineTextInputProps = Omit<
  MantineTextInputProps,
  'disabled' | 'required' | 'pointer' | 'size' | 'classNames'
>

type TSizeTextInputVariants = 'small' | 'medium' | 'large'

type TClassNamesTextInput = {
  root?: string
  label?: string
  required?: string
  description?: string
  wrapper?: string
  input?: string
  section?: string
  error?: string
}

export interface ITextInputProps extends TMantineTextInputProps {
  classNames?: TClassNamesTextInput
  size?: TSizeTextInputVariants
  isRequired?: boolean
  isDisabled?: boolean
  hasPointerCursor?: boolean
  hasFilledIcon?: boolean
}

function TextInput(
  {
    size = 'medium',
    isRequired,
    isDisabled,
    hasPointerCursor,
    hasFilledIcon,
    classNames,
    ...rest
  }: ITextInputProps,
  ref: Ref<HTMLInputElement>,
) {
  const textInputClassNames = useMemo(() => {
    const { label, description, input, section, error } = classNames || {}

    if (size === 'small') {
      return {
        label: clsx(
          'mb-1 ml-4 font-semibold md:font-normal',
          classes.textInputLabel,
          label ? label : '',
        ),
        description: clsx(
          'ml-4 mr-4',
          classes.textInputDescription,
          description ? description : '',
        ),
        error: clsx('mt-2 ml-4 mr-4', classes.textInputError, error ? error : ''),
        input: clsx(
          'h-[32px] rounded-[18px]',
          rest.leftSection ? 'pl-[32px]' : 'pl-[16px]',
          rest.rightSection ? 'pr-[32px]' : 'pr-[16px]',
          classes.textInputCore,
          input ? input : '',
        ),
        section: clsx(
          'h-[32px] w-[32px] flex items-center justify-center',
          hasFilledIcon ? classes.textInputSectionFilledIcon : '',
          classes.textInputSection,
          section ? section : '',
        ),
      }
    }

    if (size === 'large') {
      return {
        label: clsx(
          'mb-1 ml-6 font-semibold md:font-normal',
          classes.textInputLabel,
          label ? label : '',
        ),
        description: clsx(
          'ml-6 mr-6',
          classes.textInputDescription,
          description ? description : '',
        ),
        error: clsx('mt-2 ml-6 mr-6', classes.textInputError, error ? error : ''),
        input: clsx(
          'h-[56px] rounded-[25px]',
          rest.leftSection ? 'pl-[56px]' : 'pl-[25px]',
          rest.rightSection ? 'pr-[56px]' : 'pr-[25px]',
          classes.textInputCore,
          input ? input : '',
        ),
        section: clsx(
          'h-[56px] w-[56px] flex items-center justify-center',
          hasFilledIcon ? classes.textInputSectionFilledIcon : '',
          classes.textInputSection,
          section ? section : '',
        ),
      }
    }

    return {
      label: clsx(
        'mb-1 ml-5 font-semibold md:font-normal',
        classes.textInputLabel,
        label ? label : '',
      ),
      description: clsx(
        'ml-5 mr-5',
        classes.textInputDescription,
        description ? description : '',
      ),
      error: clsx('mt-2 ml-5 mr-6', classes.textInputError, error ? error : ''),
      input: clsx(
        'h-[48px] rounded-[30px]',
        rest.leftSection ? 'pl-[48px]' : 'pl-[18px]',
        rest.rightSection ? 'pr-[48px]' : 'pr-[18px]',
        classes.textInputCore,
        input ? input : '',
      ),
      section: clsx(
        'h-[48px] w-[48px] flex items-center justify-center',
        hasFilledIcon ? classes.textInputSectionFilledIcon : '',
        classes.textInputSection,
        section ? section : '',
      ),
    }
  }, [classNames, size, rest.leftSection, rest.rightSection, hasFilledIcon])

  return (
    <MantineTextInput
      classNames={{
        root: clsx(
          'w-full',
          classes.textInputRoot,
          classNames?.root ? classNames.root : '',
        ),
        required: clsx(
          '',
          classes.textInputRequired,
          classNames?.required ? classNames.required : '',
        ),
        wrapper: clsx(
          '',
          classes.textInputWrapper,
          classNames?.wrapper ? classNames.wrapper : '',
        ),
        ...textInputClassNames,
      }}
      disabled={isDisabled}
      pointer={hasPointerCursor}
      ref={ref}
      required={isRequired}
      {...rest}
    />
  )
}

export default forwardRef(TextInput)
