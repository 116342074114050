import { createTheme, MantineThemeOverride } from '@mantine/core'

const xeltoTheme: MantineThemeOverride = createTheme({
  fontFamily: 'Barlow, sans-serif',
  fontSizes: {
    xs: '16px',
  },
  colors: {
    mainColor: [
      '#0090FE', // LIGHT COLOR 0
      '#0090FE', // LIGHT COLOR 1
      '#0090FE', // LIGHT COLOR 2
      '#0F51EB', // NORMAL COLOR 3
      '#0F51EB', // NORMAL COLOR 4
      '#0F51EB', // NORMAL COLOR 5
      '#0F51EB', // NORMAL COLOR 6
      '#082E9D', // DARK COLOR 7
      '#082E9D', // DARK COLOR 8
      '#082E9D', // DARK COLOR 9
    ],
  },
  primaryColor: 'mainColor',
})

export default xeltoTheme
