import { useCallback, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Title } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { useSetUserMutation } from 'auth/api'
import { IAuthorizedUser, TJwtPayload } from 'auth/models'
import { setUser } from 'auth/store'
import { fromPayloadToUserMapper } from 'auth/utils'
import { useDispatch, useForm, useSelector } from 'common/hooks'
import Button from 'common/lib/button/Button'
import { setMenu } from 'iframe/store'
import { jwtDecode } from 'jwt-decode'
import { isEmpty } from 'lodash'
import { ROUTES } from 'services'
import { useEditUserProfileMutation, useGetUserQuery } from 'user/api'
import {
  EditUserNotificationsFormFields,
  EditUserProfileFormFields,
  EditUserSettingsFormFields,
} from 'user/components'
import {
  IEditUserProfileCombinedSettingsFormValues,
  IRequestEditUserProfile,
} from 'user/models'
import {
  USER_NOTIFICATIONS_FIELDS_DEFAULT_VALUES,
  USER_PROFILE_FIELDS_DEFAULT_VALUES,
  USER_SETTINGS_FIELDS_DEFAULT_VALUES,
} from 'user/utils'
import { array, object, string } from 'yup'

export default function SettingsPage() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const { menu } = useSelector((state) => state.iframe)
  const { mutate: editUserProfile, isPending: isEditUserProfilePending } =
    useEditUserProfileMutation()
  const { data: response, isFetching } = useGetUserQuery(user?.userId || '')
  const { mutate: setApiUser, isPending } = useSetUserMutation(user?.userId || '')

  const applicationId = useMemo(() => localStorage.getItem('application_id'), [])
  const clientId = useMemo(() => localStorage.getItem('client_id'), [])
  const siteId = useMemo(() => localStorage.getItem('site_id'), [])
  const appMenu = useMemo(() => localStorage.getItem('application_navigation'), [])

  const isLoading = useMemo(
    () => isEditUserProfilePending || isFetching || isPending,
    [isEditUserProfilePending, isFetching, isPending],
  )

  const { form } = useForm<IEditUserProfileCombinedSettingsFormValues>({
    defaultValues: {
      ...USER_PROFILE_FIELDS_DEFAULT_VALUES,
      ...USER_SETTINGS_FIELDS_DEFAULT_VALUES,
      ...USER_NOTIFICATIONS_FIELDS_DEFAULT_VALUES,
    },
    resolver: yupResolver(
      object({
        firstName: string().required(),
        lastName: string().required(),
        email: string().required(),
        position: string().max(50).required(),
        phoneNumber: string()
          .max(20)
          .test('isPhoneNumberCorrect', t('validation.incorrectPhoneNumber'), (value) => {
            const phoneRegex = /^\d{1,20}$/

            if (value === '') return true

            return phoneRegex.test(value || '')
          }),
        areaCode1: string().nullable(),
        phoneNumber2: string()
          .max(20)
          .test(
            'isPhoneNumber2Correct',
            t('validation.incorrectPhoneNumber'),
            (value) => {
              const phoneRegex = /^\d{1,20}$/

              if (value === '') return true

              return phoneRegex.test(value || '')
            },
          ),
        areaCode2: string().nullable(),
        language: string().required(),
        dateFormat: string().required(),
        numberFormat: string().required(),
        measurementSystem: string().required(),
        notification: array(),
      }),
    ),
  })

  const formValues = useMemo(() => form, [form])

  const handleEditProfileSubmit = useCallback(
    (values: IEditUserProfileCombinedSettingsFormValues) => {
      const userId = localStorage.getItem('user_id') || ''
      const payload: IRequestEditUserProfile = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        position: values.position,
        phoneNumber: values?.phoneNumber,
        areaCode: values?.areaCode1,
        phoneNumber2: values?.phoneNumber2,
        areaCode2: values?.areaCode2,
        language: values.language,
        dateFormat: values.dateFormat,
        numberFormat: values.numberFormat,
        measurementSystem: values.measurementSystem,
        sendEmail: values?.sendEmail,
        sendSMS: values?.sendSMS,
        sendNotification: values?.sendNotification,
        isActive: true,
      }
      editUserProfile(
        { userId, ...payload },
        {
          onSuccess: () => {
            setApiUser(
              {
                clientId: clientId || '',
                applicationId: applicationId || '',
                siteId: siteId || '',
              },
              {
                onSuccess: ({ data }) => {
                  localStorage.setItem('access_token', data.access_token)
                  const user = jwtDecode(
                    data.access_token,
                  ) as TJwtPayload<IAuthorizedUser>
                  i18n.changeLanguage(values.language)

                  let path = window.location.hostname
                  const origin = window.location.origin

                  if (path !== 'localhost') {
                    const pathArray = path.split('.')
                    path = `.${pathArray[pathArray.length - 2]}.${pathArray[pathArray.length - 1]}`
                  }

                  document.cookie = `auth=${data.access_token}; path=/; domain=${path}; Secure; SameSite=None;`
                  document.cookie = `origin=${origin}; path=/; domain=${path}; Secure; SameSite=None;`

                  dispatch(setUser({ user: fromPayloadToUserMapper(user) }))
                  notifications.show({
                    color: 'green',
                    message: t('user.api.editSuccess'),
                  })
                },
              },
            )
          },
        },
      )
    },
    [applicationId, clientId, dispatch, editUserProfile, i18n, setApiUser, siteId, t],
  )

  const handleBackToHomePage = useCallback(() => {
    navigate(ROUTES.home())
  }, [navigate])

  useEffect(() => {
    if (response) {
      form.reset({ ...response, areaCode1: response.areaCode })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  useEffect(() => {
    if (isEmpty(menu) && appMenu) {
      dispatch(setMenu(JSON.parse(appMenu)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu])

  return (
    <>
      <Helmet>
        <title>{t('user.page.edit', { user: response?.username || '' })}</title>
      </Helmet>
      <div className={'bg-transparent p-8'}>
        <FormProvider {...formValues}>
          <form
            className={'w-full flex flex-col gap-4'}
            onSubmit={form.handleSubmit(handleEditProfileSubmit)}
          >
            <header
              className={
                'flex items-center flex-col md:justify-between md:flex-row gap-4'
              }
            >
              <Title className={'text-[24px] leading-[inherit] font-medium'} order={2}>
                {t('user.title.edit', { user: user?.displayName })}
              </Title>
            </header>
            <div
              className={
                'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 min-[1600px]:max-w-[70vw] gap-4'
              }
            >
              <EditUserProfileFormFields isLoading={isLoading} />
              <EditUserSettingsFormFields isLoading={isLoading} />
              <EditUserNotificationsFormFields isLoading={isLoading} />
            </div>
            <div
              className={
                'hidden md:flex items-center justify-center flex-col-reverse gap-4 w-full sm:flex-row md:w-auto'
              }
            >
              <Button
                color={'gray'}
                isDisabled={isLoading}
                onClick={handleBackToHomePage}
                size={'large'}
              >
                {t('control.cancel')}
              </Button>
              <Button isDisabled={isLoading} type={'submit'}>
                {t('control.save')}
              </Button>
            </div>
            <footer
              className={
                'flex items-center flex-col-reverse gap-4 w-full sm:flex-row md:hidden'
              }
            >
              <Button
                className={'md:w-auto'}
                color={'grey'}
                isDisabled={isLoading}
                onClick={handleBackToHomePage}
              >
                {t('control.close')}
              </Button>
              <Button className={'md:w-auto'} isDisabled={isLoading} type={'submit'}>
                {t('control.save')}
              </Button>
            </footer>
          </form>
        </FormProvider>
      </div>
    </>
  )
}
