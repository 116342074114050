import { IAuthorizedUser, TJwtPayload } from 'auth/models'

export const fromPayloadToUserMapper = (
  payload: TJwtPayload<IAuthorizedUser>,
): IAuthorizedUser => ({
  client_id: payload.client_id,
  userId: payload.userId,
  clientId: payload.clientId,
  applicationId: payload.applicationId,
  siteId: payload.siteId,
  displayName: payload.displayName,
  position: payload.position,
  isMasterUser: payload.isMasterUser,
  language: payload.language,
  dateFormat: payload.dateFormat,
  numberFormat: payload.numberFormat,
  measurementSystem: payload.measurementSystem,
  permissions: payload.permissions,
  scope: payload.scope,
})

export const BASIC_GUID = '00000000-0000-0000-0000-000000000000'

export const createCookieWithTokenAndOriginUrl = (token: string) => {
  let path = window.location.hostname
  const origin = window.location.origin

  if (path !== 'localhost') {
    const pathArray = path.split('.')
    path = `.${pathArray[pathArray.length - 2]}.${pathArray[pathArray.length - 1]}`
  }

  document.cookie = `auth=${token}; path=/; domain=${path}; Secure; SameSite=None;`
  document.cookie = `origin=${origin}; path=/; domain=${path}; Secure; SameSite=None;`
}
