import { createContext, ReactNode, RefObject, useRef } from 'react'

export type IFrameContextType = RefObject<HTMLIFrameElement> | null

export const IFrameContext = createContext<IFrameContextType>(null)

interface IProps {
  children: ReactNode
}

export default function IFrameProvider({ children }: IProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  return <IFrameContext.Provider value={iframeRef}>{children}</IFrameContext.Provider>
}
