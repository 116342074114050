import { ReactNode } from 'react'
import { Text } from '@mantine/core'
import clsx from 'clsx'

interface IProps {
  title?: string
  subtitle?: string
  children?: ReactNode
  className?: string
  settingSection?: boolean
}

export default function Section({
  title,
  subtitle,
  children,
  className,
  settingSection,
}: IProps) {
  return (
    <section
      className={clsx('h-fit bg-white p-4 md:p-8 rounded-lg shadow-md', className)}
    >
      <div className={clsx('flex flex-col', settingSection ? 'gap-1' : 'gap-8')}>
        <header className={'flex flex-col gap-1'}>
          <Text className={'uppercase font-medium'}>{title}</Text>
          {subtitle && <Text className={'text-sm'}>{subtitle}</Text>}
        </header>
        {children}
      </div>
    </section>
  )
}
