import { useEffect } from 'react'
import useThemeConfig from 'config/useThemeConfig'
import { setLogo, setLogoColor } from 'iframe/store'

import useDispatch from './useDispatch'

export default function useLoadClientAssets() {
  const dispatch = useDispatch()
  const { origin } = window.location

  const { logo, theme, logoColor } = useThemeConfig(origin)

  useEffect(() => {
    if (logo) {
      // setLogoUrl(logo)
      dispatch(setLogo({ logo, isImage: logo.includes('/') }))
    } else if (logoColor) {
      dispatch(setLogoColor({ logoColor, isImageColor: logoColor.includes('/') }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { logo, theme, logoColor }
}
