import { ReactNode } from 'react'
import { MoonLoader as LibMoonLoader } from 'react-spinners'
import { Text } from '@mantine/core'
import { isString } from 'lodash'

interface IMoonLoaderProps {
  size?: number
  color?: string
  text?: string | ReactNode
  isLoading?: boolean
}

export default function MoonLoader({
  size = 60,
  color,
  text,
  isLoading = true,
}: IMoonLoaderProps) {
  if (text) {
    return (
      <div className={'flex flex-col items-center justify-center gap-4'}>
        <LibMoonLoader color={color} loading={isLoading} size={size} />
        {text && isString(text) ? <Text className={'text-gray-500'}>{text}</Text> : text}
      </div>
    )
  }

  return <LibMoonLoader color={color} loading={isLoading} size={size} />
}
