import { forwardRef, Ref, useMemo } from 'react'
import {
  Textarea as MantineTextarea,
  TextareaProps as MantineTextareaProps,
} from '@mantine/core'
import clsx from 'clsx'

import classes from './textarea-input.module.css'

type TMantineTextareaProps = Omit<MantineTextareaProps, 'classNames'>

type TClassNamesTextareaInput = {
  root?: string
  label?: string
  required?: string
  description?: string
  wrapper?: string
  section?: string
  input?: string
  error?: string
}

export interface ITextareaInputProps extends TMantineTextareaProps {
  classNames?: TClassNamesTextareaInput
}

function TextareaInput(
  { classNames, ...rest }: ITextareaInputProps,
  ref: Ref<HTMLTextAreaElement>,
) {
  const textareaInputClassNames = useMemo(() => {
    const { root, label, required, description, wrapper, section, input, error } =
      classNames || {}
    return {
      root: clsx(classes.textareaInputRoot, root ? root : ''),
      label: clsx(
        'mb-1 ml-5 font-semibold md:font-normal',
        classes.textareaInputLabel,
        label ? label : '',
      ),
      required: clsx(classes.textareaInputRequired, required ? required : ''),
      description: clsx(
        'ml-5 mr-5',
        classes.textareaInputDescription,
        description ? description : '',
      ),
      wrapper: clsx(classes.textareaInputWrapper, wrapper ? wrapper : ''),
      section: clsx(classes.textareaInputSection, section ? section : ''),
      input: clsx(
        'min-h-[100px] rounded-[30px] px-[20px]',
        classes.textareaInputInput,
        input ? input : '',
      ),
      error: clsx('mt-2 ml-5 mr-6', classes.textareaInputError, error ? error : ''),
    }
  }, [classNames])

  return <MantineTextarea classNames={textareaInputClassNames} ref={ref} {...rest} />
}

export default forwardRef(TextareaInput)
