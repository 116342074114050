import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { MantineThemeOverride } from '@mantine/core'
import { IMenuItem } from 'common/models'
import { useIFrameContext } from 'iframe/hooks'
import { setMenu, setPageTitle, setRouting } from 'iframe/store'
import { isEmpty, isNil } from 'lodash'
import { logger } from 'services'

import useDispatch from './useDispatch'

export enum ActionTypeEnum {
  CONFIG = 'CONFIG',
  UI_CHANGES = 'UI_CHANGES',
  NAVIGATION = 'NAVIGATION',
  REDIRECT = 'REDIRECT',
  CHANGE_TITLE = 'CHANGE_TITLE',
  UPDATE_SEARCH_PARAMS = 'UPDATE_SEARCH_PARAMS',
  CLOSE_APPROVAL_DETAILS = 'CLOSE_APPROVAL_DETAILS',
  BACK_TO_HOME_FROM_APPROVAL_DETAILS = 'BACK_TO_HOME_FROM_APPROVAL_DETAILS',
}

export interface ISendConfigMessage {
  originUrl: string
  accessToken: string
  userId?: string
  cssFiles?: string[]
  theme?: MantineThemeOverride
}

export interface ISendApprovalCloseDetailsMessage {
  // TODO: We don't know the state type, it can be everything
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any
}

export interface ISendApprovalBackToHomeFromDetailsMessage {
  to: string
}

export interface IGetUiChanges {}

export interface IGetNavigationMessage {
  menu: IMenuItem[]
  routes: string[]
}

export interface IGetRedirectMessage {
  to: string
  /* TODO: We don't know what params will be passed in route state, it is an object
  but we do not have sure what exactly this object contain */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any
}

export interface IGetChangeTitleMessage {
  title: string
}

export interface IGetUpdateSearchParams {
  params: object
}

export default function useMessageActions() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const iframeRef = useIFrameContext()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams()
  const [originUrl, setOriginUrl] = useState('')

  const sendConfigMessage = useCallback(
    (payload: ISendConfigMessage) => {
      const appUrl = localStorage.getItem('application_url')
      if (iframeRef?.current && iframeRef.current?.contentWindow) {
        iframeRef.current.contentWindow.postMessage(
          { type: ActionTypeEnum.CONFIG, message: { ...payload } },
          appUrl || '',
        )
      }
    },
    [iframeRef],
  )

  const sendUiChangesMessage = () => {
    /** NOT IMPLEMENTED */
  }

  const sendNavigationMessage = () => {
    /** NOT IMPLEMENTED */
  }

  const sendRedirectMessage = () => {
    /** NOT IMPLEMENTED */
  }

  const sendApprovalBackToHomeFromDetailsMessage = (
    message: ISendApprovalBackToHomeFromDetailsMessage,
  ) => {
    const appUrl = localStorage.getItem('application_url')
    const { to } = message

    if (!to) {
      logger.showWarnLog(
        '`to` has not been provided in sendRedirectMessage function in useMessageActions hook',
      )
    }

    if (iframeRef?.current && iframeRef.current?.contentWindow) {
      iframeRef.current.contentWindow.postMessage(
        { action: ActionTypeEnum.BACK_TO_HOME_FROM_APPROVAL_DETAILS, data: { to } },
        appUrl || '',
      )
    }
  }

  const sendApprovalCloseDetailsMessage = (message: ISendApprovalCloseDetailsMessage) => {
    const appUrl = localStorage.getItem('application_url')
    const { state } = message

    if (!state) {
      logger.showWarnLog(
        '`state` has not been provided in sendApprovalCloseDetailsMessage function in useMessageActions hook',
      )
    }

    if (iframeRef?.current && iframeRef.current?.contentWindow) {
      iframeRef?.current.contentWindow.postMessage(
        { action: ActionTypeEnum.CLOSE_APPROVAL_DETAILS, data: state || null },
        appUrl || '',
      )
    }
  }

  const getConfigMessage = () => {
    /** NOT IMPLEMENTED */
  }

  const getUiChangesMessage = () => {
    /** NOT IMPLEMENTED */
  }

  const getNavigationMessage = (message: IGetNavigationMessage) => {
    const { menu, routes } = message

    if (!menu) {
      logger.showWarnLog(
        '`menu` has not been provided in getNavigationMessage function in useMessageActions hook',
      )
    }

    if (!routes) {
      logger.showWarnLog(
        '`routes` has not been provided in getNavigationMessage method in useMessageActions hook',
      )
    }

    localStorage.setItem('application_navigation', JSON.stringify(menu))
    localStorage.setItem('application_routing', routes.toString())
    dispatch(setMenu(menu))
    dispatch(setRouting(routes))
  }

  const getRedirectMessage = (message: IGetRedirectMessage) => {
    const { to, params } = message
    if (!to) {
      logger.showWarnLog(
        '`to` has not been provided in getRedirectMessage function in useMessageActions hook',
      )
    }

    if (to === 'BACK') {
      navigate(-1)
    } else {
      navigate(to, { state: params || null })
    }
  }

  const getChangeTitleMessage = (message: IGetChangeTitleMessage) => {
    const { title } = message

    if (!title) {
      logger.showWarnLog(
        '`title` has not been provided in getChangeTitleMessage function in useMessageActions hook',
      )
    }

    dispatch(setPageTitle(title || 'Xelto CLOUD'))
  }

  const getUpdateSearchParamsMessage = (message: IGetUpdateSearchParams) => {
    const { params } = message

    if (isNil(params) || isEmpty(params)) {
      logger.showErrorLog(
        '`params` has not been provided in getUpdateSearchParamsMessage function in useMessageActions hook. Actions will be not taken.',
      )
      return
    }

    // @ts-expect-error Error expected because we don't know what type `params` have
    setSearchParams(params)
  }

  useEffect(() => {
    const cookiesArray = document.cookie.split(';')
    const origin =
      cookiesArray.find((cookie) => cookie.includes('origin'))?.replace('origin=', '') ||
      ''

    setOriginUrl(origin)
  }, [])

  return {
    originUrl,
    sendConfigMessage,
    sendUiChangesMessage,
    sendNavigationMessage,
    sendRedirectMessage,
    sendApprovalCloseDetailsMessage,
    sendApprovalBackToHomeFromDetailsMessage,
    getConfigMessage,
    getUiChangesMessage,
    getNavigationMessage,
    getRedirectMessage,
    getChangeTitleMessage,
    getUpdateSearchParamsMessage,
  }
}
