import { useMediaQuery } from '@mantine/hooks'
import clsx from 'clsx'

import { HeaderWrapper } from '../header'

import classes from './layout.module.css'

export default function Header() {
  const matches = useMediaQuery('(min-width: 768px)')

  return (
    <header
      className={clsx(
        'h-[60px] px-6',
        matches ? classes.headerDesktop : classes.headerMobile,
      )}
    >
      <HeaderWrapper />
    </header>
  )
}
