import { Alert, AlertProps, Text } from '@mantine/core'

interface IAlertProps extends AlertProps {
  content: string
}

export default function ErrorAlert({ content, ...rest }: IAlertProps) {
  return (
    <Alert
      classNames={{ title: 'flex justify-center' }}
      color={'red'}
      variant={'light'}
      {...rest}
    >
      <Text>{content}</Text>
    </Alert>
  )
}
