import { ISelectOption } from 'common/models'

export const APPLICATION_NAME = {
  SYSTEM_ADMINISTRATION: 'System Administration',
  WHITELIST: 'Whitelist',
  WORKFLOWS: 'Workflows',
}

export const EMPTY_SELECT_OPTION: ISelectOption = {
  id: '',
  label: '',
  value: '',
}

export const stringToColor = (string: string) => {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}

export const loadStaticFromPublic = (filename: string): string => {
  return import.meta.env.DEV ? `/public/${filename}` : `/${filename}`
}

type SearchParams = Record<string, string | string[]>

export const extractSearchParams = (searchParams: URLSearchParams): SearchParams => {
  const params: SearchParams = {}

  for (const key of searchParams.keys()) {
    const values = searchParams.getAll(key)
    params[key] = values.length > 1 ? values : values[0]
  }

  return params
}

export const getApplicationLogoPath = () => {
  const applicationName = localStorage.getItem('application_name')

  switch (applicationName) {
    case APPLICATION_NAME.WHITELIST:
      return {
        logoUrl: loadStaticFromPublic('apps/whitelist-white-blue-logo.svg'),
        height: 28,
      }
    default:
      return {
        logoUrl: loadStaticFromPublic('apps/xelcode-white-blue-logo.svg'),
      }
  }
}
