import { useQuery } from '@tanstack/react-query'
import { IResponseLoginSite } from 'auth/models'
import { TQueryOptions } from 'common/models'
import { api } from 'services'

import { ApiKeysEnum } from './apiKeys'

const getUserSites = async (clientId: string, applicationId: string) => {
  const { data } = await api.get<IResponseLoginSite[]>(
    `client/user/client/${clientId}/application/${applicationId}/sites`,
    // `/user/application/${applicationId}/sites`,
  )

  return data
}

export default function useGetUserSitesQuery(
  userId: string,
  clientId: string,
  applicationId: string,
  options: TQueryOptions<IResponseLoginSite[]> = {},
) {
  return useQuery({
    queryKey: [ApiKeysEnum.GET_USER_SITES, userId, clientId, applicationId],
    queryFn: () => getUserSites(clientId, applicationId),
    ...options,
  })
}
