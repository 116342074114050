import { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Navigate, Outlet } from 'react-router-dom'
import { useSetUserMutation } from 'auth/api'
import { IAuthorizedUser, IResponseLogin, TJwtPayload } from 'auth/models'
import { setUser } from 'auth/store'
import { createCookieWithTokenAndOriginUrl, fromPayloadToUserMapper } from 'auth/utils'
import { MoonLoader } from 'common/components'
import { ActionTypeEnum, useDispatch, useMessageActions } from 'common/hooks'
import { getToken } from 'firebase/messaging'
import { jwtDecode } from 'jwt-decode'
import { api, RoutesEnum } from 'services'

import { messaging } from '../../../firebaseConfig'

import Header from './Header'

export default function Root() {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const {
    getNavigationMessage,
    getRedirectMessage,
    getChangeTitleMessage,
    getUpdateSearchParamsMessage,
  } = useMessageActions()
  const { mutate: setApiUser, isPending } = useSetUserMutation(
    localStorage.getItem('user_id') || '',
  )

  const applicationId = useMemo(() => localStorage.getItem('application_id'), [])
  const clientId = useMemo(() => localStorage.getItem('client_id'), [])
  const siteId = useMemo(() => localStorage.getItem('site_id'), [])

  const isUser = useMemo(() => {
    const accessToken = localStorage.getItem('access_token')
    const applicationUrl = localStorage.getItem('application_url')

    if (accessToken && applicationUrl) {
      const user = jwtDecode(accessToken) as TJwtPayload<IAuthorizedUser>

      localStorage.setItem('application_url', applicationUrl)
      dispatch(setUser({ user: fromPayloadToUserMapper(user) }))
      return true
    }

    return false
  }, [dispatch])

  const requestNotificationToken = async () => {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      try {
        const registration = await navigator.serviceWorker.ready

        const currentToken = await getToken(messaging, {
          vapidKey:
            'BJ4auDvBdns1GwVwrCMcmLmKaiuLtElzQ5ttWwfNrhHKLTTTe3hk0vhXsLzP_F-htc3CauIHkR0NEQRalTzwSXM',
          serviceWorkerRegistration: registration,
        })
        if (currentToken) {
          await api.put(`notification/subscription`, { FcmToken: currentToken })
        }
      } catch (error) {
        console.error('Error while getting token:', error)
      }
    }
  }

  const initializeUser = async (data: IResponseLogin) => {
    const user = jwtDecode(data.access_token) as TJwtPayload<IAuthorizedUser>
    i18n.changeLanguage(user.language)

    localStorage.setItem('access_token', data.access_token)
    createCookieWithTokenAndOriginUrl(data.access_token)
    await requestNotificationToken()
    dispatch(setUser({ user: fromPayloadToUserMapper(user) }))
  }

  useEffect(() => {
    if (applicationId && clientId && siteId) {
      setApiUser(
        {
          clientId: clientId || '',
          applicationId: applicationId || '',
          siteId: siteId || '',
        },
        {
          onSuccess: ({ data }) => initializeUser(data),
        },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId, clientId, siteId])

  useEffect(() => {
    const handlePostMessage = (e: MessageEvent) => {
      if (e.data.type === ActionTypeEnum.NAVIGATION) {
        getNavigationMessage(e.data.message)
      }

      if (e.data.type === ActionTypeEnum.REDIRECT) {
        getRedirectMessage(e.data.message)
      }

      if (e.data.type === ActionTypeEnum.CHANGE_TITLE) {
        getChangeTitleMessage(e.data.message)
      }

      if (e.data.type === ActionTypeEnum.UPDATE_SEARCH_PARAMS) {
        getUpdateSearchParamsMessage(e.data.message)
      }
    }

    window.addEventListener('message', handlePostMessage)

    return () => {
      window.removeEventListener('message', handlePostMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isPending) {
    return (
      <>
        <Helmet>
          <title>{t('auth.page.signing')}</title>
        </Helmet>
        <div className={'w-full h-full flex items-center justify-center bg-white'}>
          <MoonLoader />
        </div>
      </>
    )
  }

  if (isUser) {
    return (
      <>
        <Header />
        <Outlet />
      </>
    )
  }

  return <Navigate to={RoutesEnum.LOGIN} />
}
