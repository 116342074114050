import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stepper } from '@mantine/core'
import { BASIC_GUID } from 'auth/utils'
import clsx from 'clsx'
import { useSelector } from 'common/hooks'
import { isNil } from 'lodash'

import classes from './components.module.css'

export default function LoginStepper() {
  const { t } = useTranslation()
  const [active, setActive] = useState(0)
  const { loginStep, user } = useSelector((state) => state.auth)

  const isSelectApplicationDisabled = useMemo(
    () => user?.clientId === BASIC_GUID || isNil(user?.clientId),
    [user],
  )

  const isSelectSiteDisabled = useMemo(
    () =>
      (user?.applicationId === BASIC_GUID || isNil(user?.applicationId)) &&
      (user?.clientId === BASIC_GUID || isNil(user?.clientId)),
    [user],
  )

  useEffect(() => {
    switch (loginStep) {
      case 2:
      default:
        setActive(0)
        break
      case 3:
        setActive(1)
        break
      case 4:
        setActive(2)
        break
    }
  }, [loginStep])

  return (
    <Stepper
      active={active}
      allowNextStepsSelect={false}
      className={'hidden md:block'}
      classNames={{
        root: 'w-full max-w-[300px]',
        step: clsx(
          'h-[145px] last-of-type:h-auto mt-0 mb-[22px]',
          classes.loginFormLoginStepperStep,
        ),
        stepLabel: 'font-medium text-[22px] leading-[20px]',
        stepDescription: 'text-base text-white leading-[24px]',
        stepIcon: classes.loginFormLoginStepperStepIcon,
        stepCompletedIcon: classes.loginFormLoginStepperStepIconCompleted,
        verticalSeparator: clsx('top-[70px]', classes.loginFormLoginStepperStepSeparator),
      }}
      orientation={'vertical'}
    >
      <Stepper.Step
        allowStepClick={false}
        allowStepSelect={false}
        description={t('auth.selectClientText')}
        label={t('auth.selectClient')}
      />
      <Stepper.Step
        allowStepClick={false}
        allowStepSelect={false}
        description={t('auth.selectApplicationText')}
        disabled={isSelectApplicationDisabled}
        label={t('auth.selectApplication')}
      />
      <Stepper.Step
        allowStepClick={false}
        allowStepSelect={false}
        description={t('auth.selectSiteText')}
        disabled={isSelectSiteDisabled}
        label={t('auth.selectSite')}
      />
    </Stepper>
  )
}
