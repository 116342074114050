import { JwtPayload } from 'jwt-decode'

export type TJwtPayload<T> = JwtPayload & T

export interface ILoginFormValues {
  username: string
  password: string
  rememberMe?: boolean
}

export interface IForgotPasswordFormValues {
  email: string
}

export interface IResetPasswordFormValues {
  password: string
  rePassword: string
}

export interface IAuthorizedUser {
  client_id: string
  userId: string
  clientId: string
  applicationId: string
  siteId: string
  displayName: string
  position: string
  isMasterUser: boolean
  language: string
  dateFormat: string
  numberFormat: string
  measurementSystem: string
  permissions: string | string[]
  scope: string[]
}

export enum AuthLoginStep {
  SIGN_IN = 1,
  SELECT_CLIENT = 2,
  SELECT_APPLICATION = 3,
  SELECT_SITE = 4,
  INDEX = 5,
  FORGOT_PASSWORD = 6,
  RESET_PASSWORD = 7,
}
