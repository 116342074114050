import { createTheme, MantineThemeOverride } from '@mantine/core'

const walsteadTheme: MantineThemeOverride = createTheme({
  fontFamily: 'Barlow, sans-serif',
  fontSizes: {
    xs: '16px',
  },
  colors: {
    mainColor: [
      '#3CA014', // LIGHT COLOR 0
      '#3CA014', // LIGHT COLOR 1
      '#3CA014', // LIGHT COLOR 2
      '#00732D', // NORMAL COLOR 3
      '#00732D', // NORMAL COLOR 4
      '#00732D', // NORMAL COLOR 5
      '#00732D', // NORMAL COLOR 6
      '#01451B', // DARK COLOR 7
      '#01451B', // DARK COLOR 8
      '#01451B', // DARK COLOR 9
    ],
  },
  primaryColor: 'mainColor',
})

export default walsteadTheme
