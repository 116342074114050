import { isString } from 'lodash'

export class Logger {
  constructor() {}

  showWarnLog(message: string) {
    if (import.meta.env.DEV) {
      console.warn(`[WARN] Platform.UI: ${message}`)
    }
  }

  showInfoLog(message: string | object) {
    if (import.meta.env.DEV) {
      if (isString(message)) {
        console.log(`[INFO] Platform.UI: ${message}`)
      } else {
        console.log('[INFO] Platform.UI:', message)
      }
    }
  }

  showErrorLog(message: string) {
    if (import.meta.env.DEV) {
      console.error(`[ERROR] Platform.UI: ${message}`)
    }
  }
}

const logger = new Logger()

export default logger
