import { useEffect, useMemo } from 'react'
import { useRoutes } from 'react-router-dom'
import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { AuthWrapper } from 'auth/components'
import clsx from 'clsx'
import { useLoadClientAssets, useSelector } from 'common/hooks'
import { Root } from 'common/layout'
import { loadStaticFromPublic } from 'common/utils'
import { IFrame } from 'iframe/pages'
import { Login, ResetPassword, ResetPasswordWithToken, Settings } from 'pages'

export enum RoutesEnum {
  LOGIN = '/login',
  RESET_PASSWORD = '/resetPassword',
  RESET_PASSWORD_WITH_TOKEN = '/resetPassword/:resetPasswordToken',
  HOME = '/',
  SETTINGS = '/settings',
  ACTIVITIES = '/activities',
}

export const ROUTES = {
  home: () => '/',
  login: () => '/login',
  resetPassword: (resetPasswordToken?: string) => `/resetPassword/${resetPasswordToken}`,
  settings: () => '/settings',
  activities: () => '/activities',
}

export default function AppContent() {
  const { routing } = useSelector((state) => state.iframe)
  const { theme } = useLoadClientAssets()

  const pathnameIncludeAuthFlow = useMemo(() => {
    const { pathname } = window.location

    return (
      pathname === RoutesEnum.LOGIN ||
      pathname === RoutesEnum.RESET_PASSWORD ||
      pathname === RoutesEnum.RESET_PASSWORD_WITH_TOKEN
    )
  }, [])

  const routingWithNoHome = useMemo(
    () =>
      ((localStorage.getItem('application_routing') || '').split(',') || routing)
        .filter((route: string) => route !== '/')
        .map((route: string) => ({ element: <IFrame url={route} />, path: route })),
    [routing],
  )

  const routes = useRoutes([
    {
      element: <Root />,
      children: [
        {
          element: <IFrame url={'/'} />,
          path: RoutesEnum.HOME,
        },
        ...routingWithNoHome,
        {
          element: <Settings />,
          path: RoutesEnum.SETTINGS,
        },
        {
          element: <Login />,
          path: '*',
        },
      ],
    },
    {
      element: <AuthWrapper />,
      children: [
        {
          path: RoutesEnum.LOGIN,
          element: <Login />,
        },
        {
          path: RoutesEnum.RESET_PASSWORD,
          element: <ResetPassword />,
        },
        {
          path: RoutesEnum.RESET_PASSWORD_WITH_TOKEN,
          element: <ResetPasswordWithToken />,
        },
        {
          element: <Login />,
          path: '*',
        },
      ],
    },
  ])

  useEffect(() => {
    const hostname = window.location.hostname
    const parts = hostname.split('.')

    if (
      hostname === 'localhost' ||
      hostname.includes('localhost') ||
      hostname.includes('app-dev') ||
      hostname.includes('app-stag') ||
      hostname.includes('app-prod') ||
      hostname.includes('app.')
    ) {
      const existingLink = document.querySelector(`link[href$="xelto.theme.css"]`)
      if (!existingLink) {
        const link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = loadStaticFromPublic('css/xelto.theme.css')

        document.head.appendChild(link)

        return () => {
          document.head.removeChild(link)
        }
      }
    } else {
      const clientThemeFileName = parts[0] === 'www' ? parts[1] : parts[0]
      const existingLink = document.querySelector(
        `link[href$="${clientThemeFileName}.theme.css"]`,
      )
      if (!existingLink) {
        const link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = loadStaticFromPublic(`css/${clientThemeFileName}.theme.css`)

        document.head.appendChild(link)

        return () => {
          document.head.removeChild(link)
        }
      }
    }
  }, [])

  return (
    <MantineProvider theme={theme}>
      <Notifications
        autoClose={8000}
        classNames={{
          root: clsx(
            'fixed right-4 z-[99999]',
            pathnameIncludeAuthFlow ? 'top-4' : 'top-[calc(60px+1rem)]',
          ),
          notification: 'mt-2',
        }}
        position={'top-right'}
      />
      {routes}
    </MantineProvider>
  )
}
