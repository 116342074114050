import { useMutation } from '@tanstack/react-query'
import { IRequestResetPassword } from 'auth/models'
import { TMutationOptions } from 'common/models'
import { api } from 'services'

import { ApiKeysEnum } from './apiKeys'

const resetPassword = async (payload: IRequestResetPassword) => {
  const { resetPasswordToken, password } = payload

  return await api.post(`client/user/resetPassword/${resetPasswordToken}`, {
    password,
  })
}

export default function useResetPasswordMutation(
  options: TMutationOptions<unknown> = {},
) {
  return useMutation({
    mutationKey: [ApiKeysEnum.RESET_PASSWORD],
    mutationFn: resetPassword,
    ...options,
  })
}
