import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import EN from 'locales/en.json'
import PL from 'locales/pl.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: { order: ['navigator'] },
    supportedLngs: ['en', 'en-US', 'pl'],
    resources: {
      en: {
        translation: EN,
      },
      'en-US': {
        translation: EN,
      },
      pl: {
        translation: PL,
      },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
