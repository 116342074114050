import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Text, ThemeIcon, Title } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import {
  IconCircleCheckFilled,
  IconCirclePlus,
  IconKey,
  IconLogin2,
} from '@tabler/icons-react'
import { useResetPasswordMutation } from 'auth/api'
import { AuthLoginStep, IResetPasswordFormValues } from 'auth/models'
import { setIsLoginPageUiCenter, setLoginStep } from 'auth/store'
import clsx from 'clsx'
import { useDispatch, useForm } from 'common/hooks'
import { Button, PasswordInput } from 'common/lib'
import { ROUTES } from 'services'
import { object, string } from 'yup'

import classes from './../components.module.css'

interface IProps {
  token: string
}

export default function ResetPasswordForm({ token }: IProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const matches = useMediaQuery('(min-width: 768px)')
  const { mutate: resetPassword, isPending } = useResetPasswordMutation()
  const [isPasswordChanged, setIsPasswordChanged] = useState(false)

  const { form } = useForm<IResetPasswordFormValues>({
    defaultValues: {
      password: '',
      rePassword: '',
    },
    resolver: yupResolver(
      object({
        password: string().required(),
        rePassword: string()
          .test(
            'isPasswordTheSame',
            t('validation.incorrectPasswords'),
            (value, context) => {
              const { password } = context.parent

              return value !== '' || value === password
            },
          )
          .required(),
      }),
    ),
  })

  const formValues = useMemo(() => form, [form])

  const handleSubmit = useCallback(
    (values: IResetPasswordFormValues) => {
      resetPassword(
        { resetPasswordToken: token, password: values.password },
        {
          onSuccess: () => {
            setIsPasswordChanged(true)
            dispatch(setIsLoginPageUiCenter({ isLoginPageUiCenter: true }))
          },
        },
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resetPassword, token],
  )

  const renderChangedPasswordContent = useCallback(() => {
    if (matches) {
      return (
        <div
          className={'max-w-[450px] mt-[60px] flex flex-col justify-center items-center'}
        >
          <ThemeIcon size={108} variant={'white'}>
            <IconCircleCheckFilled
              className={classes.loginFormForgotPasswordIcon}
              height={108}
              width={108}
            />
          </ThemeIcon>
          <Title
            className={clsx(
              'font-medium mt-6 mb-4 heading-[42px] text-center',
              classes.loginFormTitleH1,
            )}
            order={1}
          >
            {t('auth.title.resetPasswordSuccess')}
          </Title>
          <Text className={'text-center heading-[19px]'}>
            {t('auth.title.resetPasswordText')}
          </Text>
          <div className={'w-full mt-8 flex justify-center'}>
            <Button
              leftSection={<IconLogin2 size={32} />}
              onClick={() => navigate(ROUTES.login())}
              size={'large'}
              type={'submit'}
            >
              {t('auth.control.signIn')}
            </Button>
          </div>
        </div>
      )
    }

    return (
      <div className={'w-full flex flex-col items-center justify-between'}>
        <div>
          <Title className={'font-bold text-[22px] leading-[33px]'} order={1}>
            {t('auth.title.resetPasswordSuccess')}
          </Title>
          <Text className={'text-center text-[22px]'}>
            {t('auth.title.resetPasswordText')}
          </Text>
          <div
            className={clsx(
              'w-[100vw] h-[100vw] max-w-[300px] max-h-[300px] rounded-[50%] p-[60px] mt-[20px] flex items-center justify-center',
              classes.loginFormForgotPasswordMobileIconFirst,
            )}
          >
            <div
              className={clsx(
                'w-full h-full rounded-[50%] flex items-center justify-center',
                classes.loginFormForgotPasswordMobileIconSecond,
              )}
            >
              <IconCircleCheckFilled
                className={classes.loginFormForgotPasswordMobileIconIcon}
                size={80}
              />
            </div>
          </div>
        </div>
        <div className={'w-full mt-8 flex justify-center'}>
          <Button
            leftSection={<IconLogin2 size={32} />}
            onClick={() => navigate(ROUTES.login())}
            size={'large'}
            type={'submit'}
          >
            {t('auth.control.signIn')}
          </Button>
        </div>
      </div>
    )
  }, [matches, navigate, t])

  useEffect(() => {
    setIsPasswordChanged(false)
    dispatch(setLoginStep({ step: AuthLoginStep.RESET_PASSWORD }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={clsx(
        'flex flex-col justify-start w-full',
        isPasswordChanged ? 'items-center' : '',
      )}
    >
      {isPasswordChanged ? (
        renderChangedPasswordContent()
      ) : (
        <>
          <Title
            className={clsx(
              'font-medium leading-[52px] mb-8 md:mt-[60px] md:mb-4',
              classes.loginFormTitleH1,
            )}
            order={1}
          >
            {t('auth.title.resetPassword')}
          </Title>
          <Text className={'font-medium mb-8'}>{t('auth.title.resetPasswordSub')}</Text>
          <FormProvider {...formValues}>
            <form
              className={'flex flex-col gap-8 md:gap-[29px]'}
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              <div className={'flex flex-col gap-6 md:gap-[29px]'}>
                <Controller
                  control={form.control}
                  name={'password'}
                  render={({
                    field: { value, onBlur, onChange },
                    fieldState: { error },
                  }) => (
                    <PasswordInput
                      classNames={{ root: 'md:max-w-[300px]' }}
                      error={error?.message}
                      isDisabled={isPending}
                      label={t('auth.field.password')}
                      onBlur={onBlur}
                      onChange={onChange}
                      size={'large'}
                      value={value}
                    />
                  )}
                />
                <Controller
                  control={form.control}
                  name={'rePassword'}
                  render={({
                    field: { value, onBlur, onChange },
                    fieldState: { error },
                  }) => (
                    <PasswordInput
                      classNames={{ root: 'md:max-w-[300px]' }}
                      error={error?.message}
                      isDisabled={isPending}
                      label={t('auth.field.rePassword')}
                      onBlur={onBlur}
                      onChange={onChange}
                      size={'large'}
                      value={value}
                    />
                  )}
                />
              </div>
              <div
                className={
                  'flex flex-col items-start gap-6 md:items-center md:flex-row md:justify-between'
                }
              >
                <Text>{t('auth.matchPasswords')}</Text>
              </div>
              <div className={'flex justify-center md:justify-start'}>
                <Button
                  leftSection={
                    matches ? <IconKey size={32} /> : <IconCirclePlus size={32} />
                  }
                  size={'large'}
                  type={'submit'}
                >
                  {matches ? t('auth.control.setNewPassword') : t('auth.control.create')}
                </Button>
              </div>
            </form>
          </FormProvider>
        </>
      )}
    </div>
  )
}
