import { useQuery } from '@tanstack/react-query'
import { TQueryOptions } from 'common/models'
import { api } from 'services'
import { IResponseUserDetails } from 'user/models'

import { ApiKeysEnum } from './apiKeys'

const getUser = async (userId: string, includeDetails: boolean = true) => {
  const { data } = await api.get<IResponseUserDetails>(`client/user/${userId}`, {
    params: { includeDetails },
  })

  return data
}

export default function useGetUserQuery(
  userId: string,
  opions: TQueryOptions<IResponseUserDetails> = {},
) {
  return useQuery({
    queryKey: [ApiKeysEnum.GET_USER, userId],
    queryFn: () => getUser(userId),
    ...opions,
  })
}
