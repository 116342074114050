import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@mantine/core'
import { Section } from 'common/components'

interface IProps {
  isLoading: boolean
}

export default function EditUserNotificationsFormFields({ isLoading }: IProps) {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <Section settingSection title={t('user.section.notification')}>
      <div className={'flex gap-4 flex-col mt-1'}>
        <Controller
          control={control}
          name={'sendEmail'}
          render={({ field: { value, onBlur, onChange } }) => (
            <Checkbox
              checked={value}
              disabled={isLoading}
              label={t('user.field.notificationByEmail')}
              name={'sendEmail'}
              onBlur={onBlur}
              onChange={(event) => onChange(event.currentTarget.checked)}
            />
          )}
        />

        <Controller
          control={control}
          name={'sendSMS'}
          render={({ field: { value, onBlur, onChange } }) => (
            <Checkbox
              checked={value}
              disabled={isLoading}
              label={t('user.field.notificationByMessage')}
              name={'sendSMS'}
              onBlur={onBlur}
              onChange={(event) => onChange(event.currentTarget.checked)}
            />
          )}
        />

        <Controller
          control={control}
          name={'sendNotification'}
          render={({ field: { value, onBlur, onChange } }) => (
            <Checkbox
              checked={value}
              disabled={isLoading}
              label={t('user.field.notificationByPush')}
              name={'sendNotification'}
              onBlur={onBlur}
              onChange={(event) => onChange(event.currentTarget.checked)}
            />
          )}
        />
      </div>
    </Section>
  )
}
