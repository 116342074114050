import { ComponentPropsWithoutRef, forwardRef, ReactNode, Ref, useMemo } from 'react'
import {
  ActionIcon as MantineActionIcon,
  ActionIconProps as MantineActionIconProps,
} from '@mantine/core'
import clsx from 'clsx'

import classes from './action-button.module.css'

type TMantineActionIconProps = Omit<
  MantineActionIconProps,
  'variant' | 'size' | 'classNames' | 'children'
>

type TVariantActionIconVariants = 'filled' | 'outline' | 'transparent'

type TClassNamesActionButton = {
  root?: string
  loader?: string
  icon?: string
}

export interface IActionButtonProps
  extends TMantineActionIconProps,
    Omit<ComponentPropsWithoutRef<'button'>, keyof MantineActionIconProps> {
  icon: ReactNode
  classNames?: TClassNamesActionButton
  variant?: TVariantActionIconVariants
}

function ActionButton(
  { icon, classNames, variant = 'filled', ...rest }: IActionButtonProps,
  ref: Ref<HTMLButtonElement>,
) {
  const actionButtonClassNames = useMemo(() => {
    const { root } = classNames || {}

    if (variant === 'filled') {
      return {
        root: clsx(
          'w-[65px] h-[34px] duration-100',
          classes.actionButtonRoot,
          classes.actionButtonRootFilled,
          root ? root : '',
        ),
      }
    }

    if (variant === 'outline') {
      return {
        root: clsx(
          'w-[65px] h-[34px] duration-100',
          classes.actionButtonRoot,
          classes.actionButtonRootOutline,
          root ? root : '',
        ),
      }
    }

    return {
      root: clsx(
        'p-0 m-0 duration-100',
        classes.actionButtonRoot,
        classes.actionButtonRootTransparent,
        root ? root : '',
      ),
    }
  }, [variant, classNames])

  return (
    <MantineActionIcon
      classNames={{
        icon: clsx(
          'duration-100',
          classes.actionButtonIcon,
          classNames?.icon ? classNames.icon : '',
        ),
        loader: clsx(
          '',
          classes.actionButtonLoader,
          classNames?.loader ? classNames.loader : '',
        ),
        ...actionButtonClassNames,
      }}
      ref={ref}
      {...rest}
    >
      {icon}
    </MantineActionIcon>
  )
}

export default forwardRef(ActionButton)
