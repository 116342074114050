import { configureStore } from '@reduxjs/toolkit'
import { auth } from 'auth/store'
import { iframe } from 'iframe/store'

const rootReducer = {
  iframe: iframe.reducer,
  auth: auth.reducer,
}

const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
