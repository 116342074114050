import { Control, Controller } from 'react-hook-form'
import { Select, SelectProps } from '@mantine/core'
import { ISelectOption } from 'common/models'

type TSelectMantineProps = Omit<SelectProps, 'disabled' | 'required' | 'onChange'>

export interface ISingleSelectFieldProps extends TSelectMantineProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>
  name: string
  label: string
  onChange?: (option: ISelectOption) => void
  isRequired?: boolean
  isDisabled?: boolean
}

export default function SingleSelectField({
  name,
  control,
  value,
  data,
  onChange,
  isRequired,
  isDisabled,
  ...rest
}: ISingleSelectFieldProps) {
  if (control) {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
          <Select
            {...rest}
            className={'w-full'}
            data={data}
            disabled={isDisabled}
            error={error?.message}
            onChange={(_, option) => onChange((option as ISelectOption).value)}
            required={isRequired}
            value={value}
            {...field}
          />
        )}
      />
    )
  }

  return (
    <Select
      {...rest}
      data={data}
      disabled={isDisabled}
      onChange={(_, option) => {
        if (onChange) {
          onChange(option as ISelectOption)
        }
      }}
      required={isRequired}
      value={value}
    />
  )
}
