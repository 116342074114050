import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Select } from '@mantine/core'
import { Section, TextInputField } from 'common/components'

interface IProps {
  isLoading: boolean
}

export default function EditUserProfileFormFields({ isLoading }: IProps) {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <Section settingSection title={t('user.section.profile')}>
      <div className={'flex flex-col items-center w-full gap-1'}>
        <TextInputField
          control={control}
          isDisabled={isLoading}
          label={t('user.field.firstName')}
          name={'firstName'}
        />
        <TextInputField
          control={control}
          isDisabled={isLoading}
          label={t('user.field.lastName')}
          name={'lastName'}
        />
        <TextInputField
          control={control}
          isDisabled={isLoading}
          label={t('user.field.email')}
          name={'email'}
        />
        <TextInputField
          control={control}
          isDisabled={isLoading}
          label={t('user.field.position')}
          name={'position'}
        />
        <div className={'w-full flex items-end gap-2'}>
          <Controller
            control={control}
            name={'areaCode1'}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Select
                className={'w-[150px]'}
                data={[
                  { label: 'AT +43', value: '+43' },
                  { label: 'GB +44', value: '+44' },
                  { label: 'PL +48', value: '+48' },
                  { label: 'DE +49', value: '+49' },
                ]}
                disabled={isLoading}
                error={error?.message}
                label={t('user.field.phoneNumberCountry')}
                name={'areaCode2'}
                onBlur={onBlur}
                onChange={(option) => onChange(option)}
                value={value}
              />
            )}
          />
          <TextInputField
            control={control}
            isDisabled={isLoading}
            label={t('user.field.phoneNumber')}
            name={'phoneNumber'}
          />
        </div>
        <div className={'w-full flex items-end gap-2'}>
          <Controller
            control={control}
            name={'areaCode2'}
            render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
              <Select
                className={'w-[150px]'}
                data={[
                  { label: 'AT +43', value: '+43' },
                  { label: 'GB +44', value: '+44' },
                  { label: 'PL +48', value: '+48' },
                  { label: 'DE +49', value: '+49' },
                ]}
                disabled={isLoading}
                error={error?.message}
                label={t('user.field.phoneNumberCountry')}
                name={'areaCode2'}
                onBlur={onBlur}
                onChange={(option) => onChange(option)}
                value={value}
              />
            )}
          />
          <TextInputField
            control={control}
            isDisabled={isLoading}
            label={t('user.field.phoneNumber2')}
            name={'phoneNumber2'}
          />
        </div>
      </div>
    </Section>
  )
}
