import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { logger } from 'services'

import 'services/i18n'

import App from './App.tsx'

import './index.css'
import 'react-toastify/dist/ReactToastify.css'
import '@mantine/core/styles.layer.css'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
)

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js', { scope: '/' })
    .then((registration) => {
      logger.showInfoLog('Firebase Service Worker was registered on start')
      logger.showInfoLog(registration)
    })
    .catch((error) => {
      console.error('Registration of Firebase Service Workera failed:', error)
    })
}
