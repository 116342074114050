import { useMutation } from '@tanstack/react-query'
import { TMutationOptions } from 'common/models'
import { api } from 'services'
import { IRequestEditUserProfile, IResponseEditUserProfile } from 'user/models'

import { ApiKeysEnum } from './apiKeys'

type TRequestEditUser = IRequestEditUserProfile & { userId: string }

const editUserProfile = async ({ userId, ...rest }: TRequestEditUser) => {
  return await api.put<IResponseEditUserProfile>(`client/user/${userId}`, rest)
}

export default function useEditUserProfileMutation(
  options: TMutationOptions<IResponseEditUserProfile> = {},
) {
  return useMutation({
    mutationKey: [ApiKeysEnum.EDIT_USER_PROFILE],
    mutationFn: editUserProfile,
    ...options,
  })
}
