import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Anchor, Text, ThemeIcon, Title } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconMailFilled, IconSend2, IconUserFilled } from '@tabler/icons-react'
import { useForgotPasswordMutation } from 'auth/api'
import { AuthLoginStep, IForgotPasswordFormValues } from 'auth/models'
import { setIsLoginPageUiCenter, setLoginStep } from 'auth/store'
import clsx from 'clsx'
import { useDispatch, useForm } from 'common/hooks'
import { Button, TextInput } from 'common/lib'
import { ROUTES, RoutesEnum } from 'services'
import { object, string } from 'yup'

import classes from './../components.module.css'

export default function ForgotPasswordForm() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const matches = useMediaQuery('(min-width: 768px)')
  const { mutate: forgotPassword, isPending } = useForgotPasswordMutation()
  const [isEmailSent, setIsEmailSent] = useState(false)

  const { form } = useForm<IForgotPasswordFormValues>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(
      object({
        email: string().email().required(),
      }),
    ),
  })

  const formValues = useMemo(() => form, [form])

  const handleSubmit = useCallback(
    (values: IForgotPasswordFormValues) => {
      forgotPassword(values.email, {
        onSuccess: () => {
          setIsEmailSent(true)
          dispatch(setIsLoginPageUiCenter({ isLoginPageUiCenter: true }))
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forgotPassword],
  )

  const renderSentEmailContent = useCallback(() => {
    if (matches) {
      return (
        <div
          className={'max-w-[450px] mt-[60px] flex flex-col justify-center items-center'}
        >
          <ThemeIcon size={108} variant={'white'}>
            <IconMailFilled
              className={classes.loginFormForgotPasswordIcon}
              height={108}
              width={108}
            />
          </ThemeIcon>
          <Title
            className={clsx(
              'font-medium mt-6 mb-4 heading-[42px] text-center',
              classes.loginFormTitleH1,
            )}
            order={1}
          >
            {t('auth.title.forgotPasswordSuccess')}
          </Title>
          <Text className={'text-center heading-[19px]'}>
            {t('auth.title.forgotPasswordText')}
          </Text>
          <Button
            className={'mt-4'}
            onClick={() => navigate(ROUTES.login())}
            size={'large'}
          >
            {t('auth.control.backToLoginPage')}
          </Button>
        </div>
      )
    }

    return (
      <div className={'w-full flex flex-col items-center justify-between'}>
        <div className={'flex flex-col items-center'}>
          <Title className={'font-bold text-[20px] text-center mb-2'} order={1}>
            {t('auth.title.forgotPasswordSuccess')}
          </Title>
          <Text className={'text-center text-[16px]'}>
            {t('auth.title.forgotPasswordText')}
          </Text>

          <div
            className={clsx(
              'w-[100vw] h-[100vw] max-w-[200px] max-h-[200px] rounded-[50%] p-[30px] mt-[20px] flex items-center justify-center',
              classes.loginFormForgotPasswordMobileIconFirst,
            )}
          >
            <div
              className={clsx(
                'w-full h-full rounded-[50%] flex items-center justify-center',
                classes.loginFormForgotPasswordMobileIconSecond,
              )}
            >
              <IconMailFilled
                className={classes.loginFormForgotPasswordMobileIconIcon}
                size={80}
              />
            </div>
          </div>
        </div>
        <div className={'w-full mt-8 flex justify-center'}>
          <Button onClick={() => navigate(ROUTES.login())} size={'large'} type={'submit'}>
            {t('control.okay')}
          </Button>
        </div>
      </div>
    )
  }, [matches, navigate, t])

  useEffect(() => {
    dispatch(setIsLoginPageUiCenter({ isLoginPageUiCenter: false }))
    dispatch(setLoginStep({ step: AuthLoginStep.FORGOT_PASSWORD }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={clsx(
        'flex flex-col justify-start w-full',
        isEmailSent ? 'items-center' : '',
      )}
    >
      {isEmailSent ? (
        renderSentEmailContent()
      ) : (
        <>
          <Title
            className={clsx(
              'font-medium mb-8 md:my-[60px] text-[30px] lg:text-[40px] xl:text-[50px]',
            )}
            order={1}
          >
            {t('auth.title.forgotPassword')}
          </Title>
          <Title
            className={clsx('font-medium mb-8 md:mb-[29px] text-[18px] lg:text-[24px]')}
            order={2}
          >
            {t('auth.title.forgotPasswordSub')}
          </Title>
          <FormProvider {...formValues}>
            <form
              className={'flex flex-col gap-8 md:gap-[29px]'}
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              <div className={'flex flex-col gap-6 md:gap-[29px]'}>
                <Controller
                  control={form.control}
                  name={'email'}
                  render={({
                    field: { value, onBlur, onChange },
                    fieldState: { error },
                  }) => (
                    <TextInput
                      classNames={{ root: 'md:max-w-[500px]' }}
                      error={error?.message}
                      hasFilledIcon
                      isDisabled={isPending}
                      label={t('auth.field.email')}
                      leftSection={<IconUserFilled size={24} />}
                      leftSectionPointerEvents={'none'}
                      onBlur={onBlur}
                      onChange={onChange}
                      size={'large'}
                      value={value}
                    />
                  )}
                />
              </div>
              <div
                className={
                  'flex flex-col items-start gap-6 md:items-center md:flex-row md:justify-between'
                }
              >
                <Text>
                  {t('auth.cancelResetPasswordText')}
                  <Anchor
                    className={clsx(
                      'ml-1 font-extrabold no-underline focus:no-underline focus:outline-none',
                      classes.loginFormForgotPasswordLink,
                    )}
                    component={Link}
                    to={RoutesEnum.LOGIN}
                  >
                    {t('auth.control.backToLoginSite')}
                  </Anchor>
                </Text>
              </div>
              <div className={'flex justify-center md:justify-start'}>
                <Button
                  leftSection={<IconSend2 size={32} />}
                  size={'large'}
                  type={'submit'}
                >
                  {t('control.send')}
                </Button>
              </div>
            </form>
          </FormProvider>
        </>
      )}
    </div>
  )
}
