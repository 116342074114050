import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthLoginStep, IAuthorizedUser } from 'auth/models'
import { BASIC_GUID } from 'auth/utils'

export interface IAuthReducer {
  user: IAuthorizedUser | null
  loginStep: AuthLoginStep
  isLoginPageUiCenter: boolean
}

const initialState: IAuthReducer = {
  user: null,
  loginStep: AuthLoginStep.SIGN_IN,
  isLoginPageUiCenter: false,
}

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setInitialAuthState: (state) => {
      state.user = null
      state.loginStep = AuthLoginStep.SIGN_IN
    },
    setUser: (state, action: PayloadAction<{ user: IAuthorizedUser }>) => {
      state.user = action.payload.user
    },
    clearUser: (state) => {
      state.user = null
    },
    clearUserClientId: (state) => {
      state.user = { ...(state.user as IAuthorizedUser), clientId: BASIC_GUID }
    },
    clearUserApplicationid: (state) => {
      state.user = { ...(state.user as IAuthorizedUser), applicationId: BASIC_GUID }
    },
    clearUserSiteId: (state) => {
      state.user = { ...(state.user as IAuthorizedUser), siteId: BASIC_GUID }
    },
    setLoginStep: (state, action: PayloadAction<{ step: AuthLoginStep }>) => {
      state.loginStep = action.payload.step
    },
    setIsLoginPageUiCenter: (
      state,
      action: PayloadAction<{ isLoginPageUiCenter: boolean }>,
    ) => {
      state.isLoginPageUiCenter = action.payload.isLoginPageUiCenter
    },
  },
})

export const {
  setInitialAuthState,
  setUser,
  clearUser,
  clearUserClientId,
  clearUserApplicationid,
  clearUserSiteId,
  setLoginStep,
  setIsLoginPageUiCenter,
} = authReducer.actions

export default authReducer
