import { notifications } from '@mantine/notifications'
import axios, { AxiosInstance } from 'axios'
import { BACKEND_TRANSLATION_KEYS } from 'common/utils'

import i18n from './i18n'

const instance: AxiosInstance = axios.create({
  baseURL: window.BASE_API_URL || import.meta.env.VITE_BASE_API_URL || '',
  timeout: 360000,
})

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token')
    config.headers.Authorization = `Bearer ${token}`

    // console.log('instance.interceptors.request.config', config)
    return config
  },
  () => {
    // console.log('instance.interceptors.request.error', error)
  },
)

instance.interceptors.response.use(
  (response) => {
    // console.log('instance.interceptors.response.response', response)
    return response
  },
  (error) => {
    // console.log('instance.interceptors.response.error', error)
    if (error?.response) {
      if ([400, 402, 403, 404].includes(error.response.status)) {
        const hasTranslationKeys = BACKEND_TRANSLATION_KEYS.includes(
          error.response?.data?.details,
        )

        notifications.show({
          color: 'red',
          message: hasTranslationKeys
            ? i18n.t(`backend.${error.response?.data?.details}`)
            : error.response?.data?.details,
        })

        return Promise.reject(error.response)
      }

      if (error.response.status === 500) {
        notifications.show({
          color: 'red',
          message: error.response?.data?.title,
        })
        return Promise.reject(error.response)
      }
    }
  },
)

export default instance
