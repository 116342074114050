import { useMemo } from 'react'
import { Button as MantineButton, ButtonProps as MantineButtonProps } from '@mantine/core'
import clsx from 'clsx'

import classes from './button.module.css'

type TMantineButtonProps = Omit<
  MantineButtonProps,
  | 'autoContrast'
  | 'disabled'
  | 'loading'
  | 'variant'
  | 'size'
  | 'fullWidth'
  | 'classNames'
>

type TVariantButtonVariants = 'contained' | 'outlined'

type TSizeButtonVariants = 'small' | 'medium' | 'large'

type TClassNamesButton = {
  root?: string
  loader?: string
  inner?: string
  section?: string
  label?: string
}

interface IButtonProps
  extends TMantineButtonProps,
    Omit<React.ComponentPropsWithoutRef<'button'>, keyof MantineButtonProps> {
  classNames?: TClassNamesButton
  variant?: TVariantButtonVariants
  size?: TSizeButtonVariants
  isAutoContrast?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  isFullWidth?: boolean
}

export default function Button({
  classNames,
  variant = 'contained',
  size = 'medium',
  isAutoContrast,
  isDisabled,
  isLoading,
  isFullWidth,
  children,
  ...rest
}: IButtonProps) {
  const buttonClassNames = useMemo(() => {
    const { root, loader, inner, section, label } = classNames || {}

    if (size === 'small') {
      return {
        root: clsx(
          'w-full max-w-[160px] h-[36px] rounded-[30px] duration-100 p-[5px]',
          classes.buttonRoot,
          variant === 'contained' ? classes.buttonRootFilled : classes.buttonRootOutlined,
          root ? root : '',
        ),
        loader: clsx(classes.buttonLoader, loader ? loader : ''),
        inner: clsx(classes.buttonInner, inner ? inner : ''),
        section: clsx(
          'mx-0',
          rest.leftSection ? 'pr-4' : 'pr-0',
          rest.rightSection ? 'pl-4' : 'pl-0',
          classes.buttonSection,
          section ? section : '',
        ),
        label: clsx(
          'text-[16px] heading-[28px]',
          classes.buttonLabel,
          label ? label : '',
        ),
      }
    }

    if (size === 'large') {
      // return {
      //   root: clsx(
      //     'w-full max-w-[400px] h-[60px] rounded-[30px] duration-100 p-[10px]',
      //     classes.buttonRoot,
      //     variant === 'contained' ? classes.buttonRootFilled : classes.buttonRootOutlined,
      //     root ? root : '',
      //   ),
      //   loader: clsx(classes.buttonLoader, loader ? loader : ''),
      //   inner: clsx(classes.buttonInner, inner ? inner : ''),
      //   section: clsx(
      //     'mx-0',
      //     rest.leftSection ? 'pr-4' : 'pr-0',
      //     rest.rightSection ? 'pl-4' : 'pl-0',
      //     classes.buttonSection,
      //     section ? section : '',
      //   ),
      //   label: clsx(
      //     'text-[24px] heading-[28px]',
      //     classes.buttonLabel,
      //     label ? label : '',
      //   ),
      // }
      return {
        root: clsx(
          'w-full max-w-[260px] h-[45px] rounded-[30px] duration-100 p-[10px]',
          classes.buttonRoot,
          variant === 'contained' ? classes.buttonRootFilled : classes.buttonRootOutlined,
          root ? root : '',
        ),
        loader: clsx(classes.buttonLoader, loader ? loader : ''),
        inner: clsx(classes.buttonInner, inner ? inner : ''),
        section: clsx(
          'mx-0',
          rest.leftSection ? 'pr-4' : 'pr-0',
          rest.rightSection ? 'pl-4' : 'pl-0',
          classes.buttonSection,
          section ? section : '',
        ),
        label: clsx(
          'text-[20px] heading-[28px]',
          classes.buttonLabel,
          label ? label : '',
        ),
      }
    }

    return {
      root: clsx(
        'w-full max-w-[260px] h-[45px] rounded-[30px] duration-100 p-[10px]',
        classes.buttonRoot,
        variant === 'contained' ? classes.buttonRootFilled : classes.buttonRootOutlined,
        root ? root : '',
      ),
      loader: clsx(classes.buttonLoader, loader ? loader : ''),
      inner: clsx(classes.buttonInner, inner ? inner : ''),
      section: clsx(
        'mx-0',
        rest.leftSection ? 'pr-4' : 'pr-0',
        rest.rightSection ? 'pl-4' : 'pl-0',
        classes.buttonSection,
        section ? section : '',
      ),
      label: clsx('text-[20px] heading-[28px]', classes.buttonLabel, label ? label : ''),
    }
  }, [classNames, variant, size, rest])

  return (
    <MantineButton
      autoContrast={isAutoContrast}
      classNames={buttonClassNames}
      disabled={isDisabled}
      fullWidth={isFullWidth}
      loading={isLoading}
      {...rest}
    >
      {children}
    </MantineButton>
  )
}
