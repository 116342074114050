import { useEffect, useMemo } from 'react'
import { Controller, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Anchor, Title } from '@mantine/core'
import { IconLogin2, IconUserFilled } from '@tabler/icons-react'
import { useLoginMutation } from 'auth/api'
import {
  AuthLoginStep,
  IAuthorizedUser,
  ILoginFormValues,
  TJwtPayload,
} from 'auth/models'
import { setIsLoginPageUiCenter, setLoginStep, setUser } from 'auth/store'
import { fromPayloadToUserMapper } from 'auth/utils'
import clsx from 'clsx'
import { useDispatch, useForm } from 'common/hooks'
import { Button, PasswordInput, TextInput } from 'common/lib'
import { jwtDecode } from 'jwt-decode'
import { ROUTES } from 'services'
import { object, string } from 'yup'

import classes from './../components.module.css'

export default function LoginForm() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { mutate: login, isPending } = useLoginMutation()

  const { form } = useForm<ILoginFormValues>({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: yupResolver(
      object({
        username: string().required(),
        password: string().required(),
      }),
    ),
  })

  const formValues = useMemo(() => form, [form])

  const handleSubmit = (values: ILoginFormValues) => {
    login(values, {
      onSuccess: ({ data }) => {
        const token = data.access_token
        const decodedToken = jwtDecode(token) as TJwtPayload<IAuthorizedUser>

        localStorage.setItem('access_token', token)
        localStorage.setItem('user_id', decodedToken.userId)

        dispatch(setUser({ user: fromPayloadToUserMapper(decodedToken) }))
        dispatch(setLoginStep({ step: AuthLoginStep.SELECT_CLIENT }))
      },
    })
  }

  useEffect(() => {
    dispatch(setIsLoginPageUiCenter({ isLoginPageUiCenter: true }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={'flex flex-col justify-start w-full'}>
      <Title
        className={clsx('font-medium mb-8 md:mb-7 md:mt-14', classes.loginFormTitleH2)}
        order={2}
      >
        {t('auth.title.login')}
      </Title>
      <FormProvider {...formValues}>
        <form
          className={'flex flex-col gap-8 md:gap-[29px]'}
          onSubmit={form.handleSubmit(handleSubmit)}
        >
          <div className={'flex flex-col gap-6 md:gap-[29px] md:grid md:grid-cols-2'}>
            <Controller
              control={form.control}
              name={'username'}
              render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                <TextInput
                  classNames={{ input: 'text-[22px]' }}
                  error={error?.message}
                  hasFilledIcon
                  isDisabled={isPending}
                  label={t('auth.field.username')}
                  leftSection={<IconUserFilled size={24} />}
                  onBlur={onBlur}
                  onChange={onChange}
                  size={'large'}
                  value={value}
                />
              )}
            />
            <Controller
              control={form.control}
              name={'password'}
              render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
                <PasswordInput
                  classNames={{ input: 'text-[22px]' }}
                  error={error?.message}
                  isDisabled={isPending}
                  label={t('auth.field.password')}
                  onBlur={onBlur}
                  onChange={onChange}
                  size={'large'}
                  value={value}
                />
              )}
            />
          </div>
          <div
            className={
              'flex flex-col items-start gap-6 md:items-center md:flex-row md:justify-between'
            }
          >
            <Anchor
              className={clsx(
                'text-[14px] font-medium no-underline focus:no-underline focus:outline-none',
                classes.loginFormForgotPasswordLink,
              )}
              component={Link}
              to={ROUTES.resetPassword('')}
            >
              {t('auth.forgotPasswordText')}
            </Anchor>
          </div>
          <div className={'flex justify-center md:justify-start'}>
            <Button
              isDisabled={isPending}
              leftSection={<IconLogin2 size={32} />}
              size={'large'}
              type={'submit'}
            >
              {t('auth.control.signIn')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}
