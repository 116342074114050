import { ISelectOption } from 'common/models'
import { IActivitiesItem } from 'user/models'

export const LANGUAGES: ISelectOption[] = [
  {
    value: 'en',
    label: 'English',
  },
  { value: 'pl', label: 'Polski' },
  { value: 'de', label: 'Deutsh' },
]

export const DATE_FORMATS: ISelectOption[] = [
  {
    value: 'YYYY-MM-DD',
    label: 'YYYY-MM-DD',
  },
  {
    value: 'MM/DD/YYYY',
    label: 'MM/DD/YYYY',
  },
  {
    value: 'DD.MM.YYYY',
    label: 'DD.MM.YYYY',
  },
]

export const NUMBER_FORMATS: ISelectOption[] = [
  {
    value: '###,##',
    label: '###,##',
  },
  {
    value: '###.##',
    label: '###.##',
  },
]

export const MEASUREMENT_SYSTEM_FORMATS: ISelectOption[] = [
  {
    value: 'metric',
    label: 'Metric',
  },
  {
    value: 'imperial',
    label: 'Imperial',
  },
]

export const ACTIVITIES_TYPE: ISelectOption[] = [
  {
    value: 'all',
    label: 'All logs',
  },
  {
    value: 'error',
    label: 'Errors',
  },
  {
    value: 'warning',
    label: 'Warnings',
  },
  {
    value: 'info',
    label: 'Info',
  },
]

export const ACTIVITIES_APPLICATION: ISelectOption[] = [
  {
    value: 'all',
    label: 'All applications',
  },
  {
    value: 'einvoice',
    label: 'eInvoice',
  },
  {
    value: 'apautomation',
    label: 'AP Automation',
  },
]

export const ACTIVITIES_LIST: IActivitiesItem[] = [
  {
    id: 0,
    type: 'info',
    application: 'einvoice',
    label: 'eInvoice',
    date: new Date('2022-06-13T11:29:44.000'),
    title: 'INFO - Lorem ipsum',
    description:
      'In reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla.',
  },
  {
    id: 1,
    type: 'warning',
    application: 'einvoice',
    label: 'eInvoice',
    date: new Date('2022-06-13T11:29:44.000'),
    title: 'WARNING - Lorem ipsum',
    description:
      'Aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos.',
  },
  {
    id: 2,
    type: 'error',
    application: 'einvoice',
    label: 'eInvoice',
    date: new Date('2022-06-13T11:29:44.000'),
    title: 'ERROR - Lorem ipsum',
    description: 'In reprehenderit in voluptate velit esse.',
  },
  {
    id: 3,
    type: 'info',
    application: 'apautomation',
    label: 'AP Automation',
    date: new Date('2022-06-13T11:29:44.000'),
    title: 'INFO - Lorem ipsum',
    description:
      'In reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla.',
  },
  {
    id: 4,
    type: 'warning',
    application: 'apautomation',
    label: 'AP Automation',
    date: new Date('2022-06-13T11:29:44.000'),
    title: 'WARNING - Lorem ipsum',
    description:
      'Aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos.',
  },
  {
    id: 5,
    type: 'error',
    application: 'apautomation',
    label: 'AP Automation',
    date: new Date('2022-06-13T11:29:44.000'),
    title: 'ERROR - Lorem ipsum',
    description: 'In reprehenderit in voluptate velit esse.',
  },
]
