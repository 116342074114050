import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ResetPasswordForm } from 'auth/components'

export default function ResetPasswordWithToken() {
  const { t } = useTranslation()
  const { resetPasswordToken } = useParams()

  return (
    <>
      <Helmet>
        <title>{t('auth.page.resetPassword')}</title>
      </Helmet>
      <ResetPasswordForm token={resetPasswordToken as string} />
    </>
  )
}
