import { useMutation } from '@tanstack/react-query'
import { TMutationOptions } from 'common/models'
import { api } from 'services'

import { ApiKeysEnum } from './apiKeys'

const forgotPassword = async (email: string) => {
  return await api.post('client/user/forgotPassword', { email })
}

export default function useForgotPasswordMutation(
  options: TMutationOptions<unknown> = {},
) {
  return useMutation({
    mutationKey: [ApiKeysEnum.FORGOT_PASSWORD],
    mutationFn: forgotPassword,
    ...options,
  })
}
