import { forwardRef, Ref } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ActionIcon, Indicator, Menu as MantineMenu } from '@mantine/core'
import { IconMenu2 } from '@tabler/icons-react'
import clsx from 'clsx'
import { IMenuItem } from 'common/models'

import classes from './header.module.css'

interface IProps {
  visibleMenu: IMenuItem[]
  hiddenMenu: IMenuItem[]
  isDropdownMenuVisible: boolean
  setIsDropdownMenuVisible: (value: boolean) => void
}

function Menu(
  { visibleMenu, hiddenMenu, isDropdownMenuVisible, setIsDropdownMenuVisible }: IProps,
  ref: Ref<HTMLDivElement>,
) {
  const location = useLocation()

  return (
    <div className={'flex items-center justify-between flex-1 gap-2'} ref={ref}>
      <nav className={'flex items-center gap-6 ml-10'}>
        {visibleMenu.map((item) => (
          <Link
            className={clsx(
              'no-underline  font-normal text-[16px] text-white',
              location.pathname === item.link &&
                '!underline underline-offset-8 decoration-2',
            )}
            key={item.link}
            to={item.link}
          >
            {item.label}
          </Link>
        ))}
      </nav>
      <MantineMenu
        classNames={{ dropdown: 'flex flex-col gap-1 !w-[250px] p-4' }}
        onChange={setIsDropdownMenuVisible}
        opened={isDropdownMenuVisible}
        withinPortal={false}
      >
        <MantineMenu.Target>
          <Indicator
            className={hiddenMenu.length > 0 ? 'block' : 'hidden'}
            classNames={{ indicator: classes.indicatorNumber }}
            inline
            label={hiddenMenu.length}
            size={14}
          >
            <ActionIcon
              className={clsx(
                'active:transform-none text-white',
                hiddenMenu.length > 0 ? 'block' : 'hidden',
              )}
              // onClick={() => setIsDropdownVisible(!isDropdownVisible)}
              variant={'transparent'}
            >
              <IconMenu2 stroke={2} />
            </ActionIcon>
          </Indicator>
        </MantineMenu.Target>
        <MantineMenu.Dropdown>
          {hiddenMenu.map((item) => (
            <Link
              className={'no-underline font-medium text-black'}
              key={item.link}
              onClick={() => {
                if (isDropdownMenuVisible) {
                  setIsDropdownMenuVisible(false)
                }
              }}
              to={item.link}
            >
              {item.label}
            </Link>
          ))}
        </MantineMenu.Dropdown>
      </MantineMenu>
    </div>
  )
}

export default forwardRef(Menu)
