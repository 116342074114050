import { useQuery } from '@tanstack/react-query'
import { IResponseLoginClient } from 'auth/models'
import { TQueryOptions } from 'common/models'
import { api } from 'services'

import { ApiKeysEnum } from './apiKeys'

const getUserClients = async () => {
  const { data } = await api.get<IResponseLoginClient[]>('client/user/clients')

  return data
}

export default function useGetUserClientsQuery(
  userId: string,
  options: TQueryOptions<IResponseLoginClient[]> = {},
) {
  return useQuery({
    queryKey: [ApiKeysEnum.GET_USER_CLIENTS, userId],
    queryFn: getUserClients,
    ...options,
  })
}
