import { useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useYupLocale } from 'common/hooks'
import { loadStaticFromPublic } from 'common/utils'
import { IFrameProvider } from 'iframe/store'
import { store } from 'services'
import AppContent from './AppContent'

export default function App() {
  useYupLocale()

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        retry: 0,
      },
    },
  })

  useEffect(() => {
    const existingLink = document.querySelector(`link[href$="custom.css"]`)

    if (!existingLink) {
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href = loadStaticFromPublic('css/common.css')

      document.head.appendChild(link)

      return () => {
        document.head.removeChild(link)
      }
    }
  }, [])

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <IFrameProvider>
            <BrowserRouter>
              <AppContent />
            </BrowserRouter>
          </IFrameProvider>
        </HelmetProvider>
      </QueryClientProvider>
    </Provider>
  )
}
