import { forwardRef, Ref, useMemo } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

interface IProps {
  logoUrl: string
  width?: number
  height?: number
  alt?: string
  link?: string
  classes?: {
    image?: string
    link?: string
  }
  onClick?: () => void
}

function ClientLogo(
  { logoUrl, width, height = 20, alt, link, classes, onClick }: IProps,
  ref: Ref<HTMLAnchorElement>,
) {
  const renderLogo = useMemo(
    () => (
      <img
        alt={alt}
        className={classes?.image}
        height={height}
        src={logoUrl}
        width={width}
      />
    ),
    [alt, classes?.image, height, logoUrl, width],
  )

  if (link) {
    return (
      <Link
        className={clsx('inline-block', classes?.link)}
        onClick={onClick}
        ref={ref}
        to={link}
      >
        {renderLogo}
      </Link>
    )
  }

  return renderLogo
}

export default forwardRef(ClientLogo)
