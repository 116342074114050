import { Control, Controller } from 'react-hook-form'
import { TextInput, TextInputProps } from '@mantine/core'

type TMantineTextInputProps = Omit<TextInputProps, 'disabled' | 'required'>

export interface ITextInputFieldProps extends TMantineTextInputProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>
  isRequired?: boolean
  isDisabled?: boolean
}

export default function TextInputField({
  name,
  control,
  isRequired,
  isDisabled,
  ...rest
}: ITextInputFieldProps) {
  if (control) {
    return (
      <Controller
        control={control}
        name={name as string}
        render={({ field: { value, onBlur, onChange }, fieldState: { error } }) => (
          <TextInput
            className={'w-full'}
            disabled={isDisabled}
            error={error?.message}
            onBlur={onBlur}
            onChange={onChange}
            required={isRequired}
            value={value}
            {...rest}
          />
        )}
      />
    )
  }

  return <TextInput disabled={isDisabled} required={isRequired} {...rest} />
}
