import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { ForgotPasswordForm } from 'auth/components'

export default function ResetPassword() {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t('auth.page.forgotPassword')}</title>
      </Helmet>
      <ForgotPasswordForm />
    </>
  )
}
