import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import {
  LoginForm,
  LoginSelectApplicationForm,
  LoginSelectClientForm,
  LoginSelectSiteForm,
} from 'auth/components'
import { AuthLoginStep } from 'auth/models'
import { useSelector } from 'common/hooks'
import { RoutesEnum } from 'services'

export default function LoginPage() {
  const { t } = useTranslation()
  const { loginStep } = useSelector((state) => state.auth)

  const renderContent = useMemo(() => {
    switch (loginStep) {
      case AuthLoginStep.SIGN_IN:
      default:
        return <LoginForm />
      case AuthLoginStep.SELECT_CLIENT:
        return <LoginSelectClientForm />
      case AuthLoginStep.SELECT_APPLICATION:
        return <LoginSelectApplicationForm />
      case AuthLoginStep.SELECT_SITE:
        return <LoginSelectSiteForm />
      case AuthLoginStep.INDEX:
        return <Navigate to={RoutesEnum.HOME} />
    }
  }, [loginStep])

  return (
    <>
      <Helmet>
        <title>{t('auth.page.signIn')}</title>
      </Helmet>
      {renderContent}
    </>
  )
}
