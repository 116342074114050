import { useQuery } from '@tanstack/react-query'
import { IResponseLoginApplication } from 'auth/models'
import { TQueryOptions } from 'common/models'
import { api } from 'services'

import { ApiKeysEnum } from './apiKeys'

const getUserApplications = async (clientId: string) => {
  const { data } = await api.get<IResponseLoginApplication[]>(
    `client/user/client/${clientId}/applications`,
  )

  return data
}

export default function useGetUserApplicationsQuery(
  userId: string,
  clientId: string,
  options: TQueryOptions<IResponseLoginApplication[]> = {},
) {
  return useQuery({
    queryKey: [ApiKeysEnum.GET_USER_APPLICATIONS, userId, clientId],
    queryFn: () => getUserApplications(clientId),
    ...options,
  })
}
