import { createSlice } from '@reduxjs/toolkit'
import { IMenuItem } from 'common/models'

export interface IFrameReducer {
  iframeUrl: string
  logo: string
  logoColor: string
  title: string | null
  menu: IMenuItem[] | null
  routing: string[] | null
  isTokenChanged: boolean
  isLoading: boolean
  isLogoImage: boolean
  isLogoColorImage: boolean
}

const initialState: IFrameReducer = {
  iframeUrl: '',
  logo: '',
  logoColor: '',
  title: null,
  menu: null,
  routing: null,
  isTokenChanged: false,
  isLoading: true,
  isLogoImage: true,
  isLogoColorImage: true,
}

const iframeReducer = createSlice({
  name: 'iframe',
  initialState,
  reducers: {
    setInitialIframeState: (state) => {
      state.iframeUrl = ''
      state.logo = ''
      state.title = null
      state.menu = null
      state.routing = null
      state.isLoading = true
      state.isTokenChanged = false
      state.isLogoImage = true
    },
    setMenu: (state, action) => {
      state.menu = action.payload
    },
    clearMenu: (state) => {
      state.menu = null
    },
    setRouting: (state, action) => {
      state.routing = action.payload
    },
    clearRouting: (state) => {
      state.routing = null
    },
    setLogo: (state, action) => {
      state.logo = action.payload.logo
      state.isLogoImage = action.payload.isImage
    },
    setLogoColor: (state, action) => {
      state.logo = action.payload.logoColor
      state.isLogoColorImage = action.payload.isImageColor
    },
    setIframeUrl: (state, action) => {
      state.iframeUrl = action.payload
    },
    setIsTokenChanged: (state, action) => {
      state.isTokenChanged = action.payload
    },
    setPageTitle: (state, action) => {
      state.title = action.payload
    },
    setLoader: (state, action) => {
      state.isLoading = action.payload
    },
    enableLoader: (state) => {
      state.isLoading = true
    },
    disableLoader: (state) => {
      state.isLoading = false
    },
  },
})

export const {
  setInitialIframeState,
  setMenu,
  clearMenu,
  setLogo,
  setLogoColor,
  setRouting,
  clearRouting,
  setIframeUrl,
  setIsTokenChanged,
  setLoader,
  setPageTitle,
  enableLoader,
  disableLoader,
} = iframeReducer.actions

export default iframeReducer
